/** Last updated on May 29, 2024. */
import {Params} from '@angular/router';

export const decodeQueryParams = (queryParams: Params): string => {
  return decodeURIComponent(queryParams.toString());
};

export const parseQueryParams = (queryParams: Params): string => {
  // validate
  if (!queryParams) {
    return ''; // END
  }
  // handle
  //    (if) convert int to string
  for (const key in queryParams) {
    if (Number.isInteger(queryParams[key])) {
      queryParams[key] = String(queryParams[key]); // Convert to string
    }
  }
  //
  return JSON.stringify(queryParams)
    .replace(/":"/g, '=')
    .replace(/["{}]/g, '')
    .replace(/,/g, '&'); // END
};
