import { Validators } from '@angular/forms';
import { Settings } from '../../model/settings.model';
import { getFieldValue, regex } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';


export const getSettingsFields = (data: Settings) => {
  const lastInvoiceNumber = getFieldValue(data, 'lastInvoiceNumber');
  return {
    remarks: [getFieldValue(data, 'remarks')],
    currency: [getFieldValue(data, 'currency')],
    lastInvoiceNumber: [
      lastInvoiceNumber ? lastInvoiceNumber + 1 : 1,
      [
        Validators.required,
        Validators.min(0),
        noWhitespaceValidator
      ],
    ],
    prefixInvoice: [
      getFieldValue(data, 'prefixInvoice'),
            [
              Validators.required,
              Validators.maxLength(5),
              noWhitespaceValidator
            ]
    ],
    invoiceDueDateAfter: [
      getFieldValue(data, 'invoiceDueDateAfter') || 0,
      [
        Validators.required,
        Validators.max(30),
        noWhitespaceValidator,
      ],
    ],
    invoiceLanguage: [
      getFieldValue(data, 'invoiceLanguage'),
      [
        Validators.required,
        noWhitespaceValidator,
      ],
    ],
    invoiceTemplate: [
      getFieldValue(data, 'invoiceTemplate'),
      [
        Validators.required,
        noWhitespaceValidator
      ]
    ],
    invoiceMonthlyLessonTemplate: [
      getFieldValue(data, 'invoiceMonthlyLessonTemplate'),
      [
        Validators.required,
        noWhitespaceValidator,
      ],
    ],
    invoiceTax: [
      getFieldValue(data, 'invoiceTax'),
              [
                Validators.required,
                Validators.pattern(regex.numbers),
                Validators.max(100),
                Validators.min(0),
                noWhitespaceValidator
              ],
    ],
    includeUnscheduledLessons: [
      getFieldValue(data, 'includeUnscheduledLessons') || false,
              [
                Validators.required,
                noWhitespaceValidator
              ]
    ],
    totalGross: [
      getFieldValue(data, 'totalGross') || 0,
    ],
    logonLocationRequired: [
      getFieldValue(data, 'logonLocationRequired') || false,
    ],
    sendEmailToStudent: [
      getFieldValue(data, 'sendEmailToStudent'),
    ]
  };
};


