import { map, filter, mergeMap } from 'rxjs/operators';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LanguageService } from './core/language/services/language.service';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { datepickerFormat } from '../utils';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { steps } from '../constants/step-helper';
import { TourService } from 'ngx-tour-md-menu';
import * as moment from 'moment';
import { AuthService } from './core/auth/shared/auth.service';
import { AuthRepository } from './core/auth/shared/auth.repository';
import {SpinnerService} from './services/spinner.service';
import {devLogWarn} from '../utils/env.utils';

@Component({
  selector: 'e-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: datepickerFormat },
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  titleSuffix = '| EngagePlus';
  titleDefault = `Admin panel ${this.titleSuffix}`;
  spinnerActive = false;
  isLoggedIn = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private language: LanguageService,
    private adapter: DateAdapter<any>,
    private translate: TranslateService,
    public tourService: TourService,
    private authService: AuthService,
    private authRepository: AuthRepository,
    private spinnerService: SpinnerService,
  ) {
    this.tourService.initialize(steps, { route: 'calendar' });
  }

  ngOnInit() {
    this.setTitle();
    this.language.initLanguage();
    this.setLocale();
    this.isLoggedIn = this.authService.isLoggedIn();
    this.spinnerService.status.subscribe((status: boolean) => {
      setTimeout(() => {
        this.spinnerActive = status;
        this.isLoggedIn = this.authService.isLoggedIn();
      });
    });
    // handle refresh page
    this.checkPageRefresh();
  }

  setTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => this.getLastActivatedRoute(route)),
      filter(({ outlet }) => outlet === 'primary'),
      mergeMap(({ data }) => data)
    )
    .subscribe(event => {
      window.scrollTo(0, 0);
      this.titleService.setTitle(this.getTitle(event));
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.pushLogIntoLocalStorage(window.location.href);
    });
  }

  getTitle(event) {
    return event['title']
      ? `${event['title']} ${this.titleSuffix}`
      : this.titleDefault;
  }

  setLocale() {
    let code = this.language.getLanguageCode(this.translate.currentLang);
    this.adapter.setLocale(code);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      code = this.language.getLanguageCode(event.lang);
      this.adapter.setLocale(code);
    });
  }

  private getLastActivatedRoute(route): any {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  pushLogIntoLocalStorage(url) {
    let existsLog = JSON.parse(this.authService.getSyncLog());
    if (existsLog === null) {
      existsLog = [];
    }
    const log = {
      objectName: url,
      params: '',
      objectType: 'User_Interaction',
      actionDate: moment().utc().format(),
      result: 'success'
    };
    existsLog.push(log);

    if (existsLog.length > 20) {
      const data = existsLog.slice(0, 20);
      this.authRepository.syncLog(data).subscribe(res => {
        const dataRemain = existsLog.slice(20, existsLog.length);
        this.authService.setSyncLog(JSON.stringify(dataRemain));
      }, (err) => {
      });
    } else {
      this.authService.setSyncLog(JSON.stringify(existsLog));
    }
  }

  checkPageRefresh() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Check if the navigation was triggered by a refresh or initial load
      if (event.id === 1 && event.urlAfterRedirects === event.url) {
        // Perform actions for page refresh
        devLogWarn(`Perform actions for page refresh. Do some post-work`);
        this.spinnerService.resetCountRunning();
        this.spinnerService.stop();
      } else {
        // Perform actions for initial load
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerActive = false;
    });
  }

}
