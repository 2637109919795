import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {concat, of} from 'rxjs';
import {catchError, filter, map, mergeMap} from 'rxjs/operators';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import * as QueryActions from '../../../store/query/query.actions';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import * as SettingsActions from './settings.actions';
import * as settingsActions from './settings.actions';
import * as AuthActions from '../../../core/auth/store/auth/auth.actions';
import {
  DELETE_CLASSROOM,
  DELETE_LESSON_SETTINGS,
  DELETE_LOCATION,
  DELETE_MISCELLANEOUS_ITEM,
  GET_CENTER_HOLIDAYS,
  GET_LESSON_SETTINGS,
  GET_LOCATIONS,
  GET_MISCELLANEOUS_ITEMS,
  GET_NOTIFICATION_SETTING,
  GET_PROFILE_DYNAMIC_FIELD_SETTINGS,
  GET_REMINDER_SETTING,
  GET_SETTINGS,
  GET_STUDENT_PARENT_SETTING,
  GET_TEACHER_STAFF_SETTING,
  POST_CLASSROOM,
  POST_EMAIL_TEMPLATE,
  POST_LESSON_SETTING,
  POST_LOCATION,
  POST_MISCELLANEOUS_ITEM,
  PUT_CLASSROOM,
  PUT_EMAIL_TEMPLATE,
  PUT_LESSON_SETTING,
  PUT_LOCATION,
  PUT_MISCELLANEOUS_ITEM,
  PUT_ONLINE_REIGSTRATION_SETTING,
  PUT_PRIMARY_LOCATION,
  PUT_SETTINGS,
  PUT_STUDENT_PARENT_SETTING,
  GET_PREVIEW_PDF,
} from './settings.state';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {SettingsRepository} from '../../shared/settings.repository';
import {checkRouter} from '../../../../utils';
import * as LearningCenterActions from '../../../core/learning-center/store/learning-center.actions';
import {routerActions, RouterState} from '../../../store/router';
import {UIEnum} from '../../../../constants/UI.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Injectable()
export class SettingsEffects {

  constructor(
    private actions$: Actions,
    private settingsRepository: SettingsRepository,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() updateSettings$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateSettings>(SettingsActions.UPDATE_SETTINGS),
      mergeMap(({ settings }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_SETTINGS)),
          this.settingsRepository
            .putSettings(settings)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(PUT_SETTINGS, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.saveSuccess')),
                of(new AuthActions.UpdateLearningCenterSettingOnProfile(data))
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_SETTINGS, error)),
              )),
            ),
        );
      }),
    );

  @Effect() getSettings$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetSettings>(SettingsActions.GET_SETTINGS),
      mergeMap(() => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_SETTINGS)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getSettings()
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_SETTINGS, data)),
                of(this.spinnerService.stop()),
                of(new SettingsActions.GetSettingsSuccess(data)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_SETTINGS, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() fetchCategories$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'settings/category')),
      mergeMap(({ url, queryParams }) => concat(
        of(new LearningCenterActions.SetLearningCenterSpecializations(queryParams)),
        of(new SettingsActions.GetSettings())
      )),
    );

  @Effect() fetchLocations$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'settings/location')),
      mergeMap(({ url, queryParams }) => concat(
        of(new SettingsActions.GetLocations(queryParams)),
        of(new SettingsActions.GetSettings())
      )),
    );

  @Effect() getLocations$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetLocations>(SettingsActions.GET_LOCATIONS),
      mergeMap(({ queryParams }) => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_LOCATIONS)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getLocations(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_SETTINGS)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_LOCATIONS, data)),
                of(this.spinnerService.stop()),
                of(new SettingsActions.GetLocationsSuccess(data)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_LOCATIONS, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() postLocation$ = this.actions$
    .pipe(
      ofType<SettingsActions.CreateLocation>(SettingsActions.POST_LOCATION),
      mergeMap(({ data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(POST_LOCATION)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .postLocation(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(POST_LOCATION, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.createSuccess')),
                of(new routerActions.Navigate({ url: '/settings/location' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_LOCATION, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() putLocation$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateLocation>(SettingsActions.PUT_LOCATION),
      mergeMap(({ id, data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_LOCATION)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .putLocation(id, data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_LOCATION, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.saveSuccess')),
                of(new routerActions.Navigate({ url: '/settings/location' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_LOCATION, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() deleteLocation$ = this.actions$
    .pipe(
      ofType<SettingsActions.DeleteLocation>(SettingsActions.DELETE_LOCATION),
      mergeMap(({ id }) => {
        return concat(
          of(new QueryActions.QueryInProgress(DELETE_LOCATION)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .deleteLocation(id)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(DELETE_LOCATION, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.deleteSuccess')),
                of(new routerActions.Navigate({ url: '/settings/location' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(DELETE_LOCATION, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() fetchLessonSettings$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'settings/lesson')),
      mergeMap(({ url, queryParams }) => concat(
        of(new SettingsActions.GetLessonSettings(queryParams)),
        of(new SettingsActions.GetSettings())
      )),
    );

  @Effect() getLessonSettings$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetLessonSettings>(SettingsActions.GET_LESSON_SETTINGS),
      mergeMap(({ queryParams }) => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_LESSON_SETTINGS)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getLessonSettings(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_SETTINGS)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_LESSON_SETTINGS, data)),
                of(this.spinnerService.stop()),
                of(new SettingsActions.GetLessonSettingsSuccess(data)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_LESSON_SETTINGS, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() postLessonSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.CreateLessonSetting>(SettingsActions.POST_LESSON_SETTING),
      mergeMap(({ data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(POST_LESSON_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .postLessonSetting(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(POST_LESSON_SETTING, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.lesson.createSuccess')),
                of(new routerActions.Navigate({ url: '/settings/lesson' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_LESSON_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() putLessonSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateLessonSetting>(SettingsActions.PUT_LESSON_SETTING),
      mergeMap(({ id, data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_LESSON_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .putLessonSetting(id, data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_LESSON_SETTING, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.lesson.saveSuccess')),
                of(new routerActions.Navigate({ url: '/settings/lesson' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_LESSON_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() deleteLessonSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.DeleteLessonSettings>(SettingsActions.DELETE_LESSON_SETTINGS),
      mergeMap(({ queryParams }) => {
        return concat(
          of(new QueryActions.QueryInProgress(DELETE_LESSON_SETTINGS)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .deleteLessonSettings(queryParams)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(DELETE_LESSON_SETTINGS, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.lesson.deleteSuccess')),
                of(new routerActions.Navigate({ url: '/settings/lesson' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(DELETE_LESSON_SETTINGS, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() fetchMiscellaneousItems$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'settings/miscellaneous')),
      mergeMap(({ url, queryParams }) => concat(
        of(new SettingsActions.GetMiscellaneousItems(queryParams))
      )),
    );

  @Effect() getMiscellaneousItems$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetMiscellaneousItems>(SettingsActions.GET_MISCELLANEOUS_ITEMS),
      mergeMap(({ queryParams }) => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_MISCELLANEOUS_ITEMS)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getMiscellaneousItems(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_SETTINGS)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_MISCELLANEOUS_ITEMS, data)),
                of(this.spinnerService.stop()),
                of(new SettingsActions.GetMiscellaneousItemsSuccess(data)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_MISCELLANEOUS_ITEMS, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() postMiscellaneousItem$ = this.actions$
    .pipe(
      ofType<SettingsActions.CreateMiscellaneousItem>(SettingsActions.POST_MISCELLANEOUS_ITEM),
      mergeMap(({ data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(POST_MISCELLANEOUS_ITEM)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .postMiscellaneousItem(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(POST_MISCELLANEOUS_ITEM, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.miscellaneous.createSuccess')),
                of(new routerActions.Navigate({ url: '/settings/miscellaneous' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_MISCELLANEOUS_ITEM, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() putMiscellaneousItem$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateMiscellaneousItem>(SettingsActions.PUT_MISCELLANEOUS_ITEM),
      mergeMap(({ id, data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_MISCELLANEOUS_ITEM)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .putMiscellaneousItem(id, data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_MISCELLANEOUS_ITEM, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.miscellaneous.saveSuccess')),
                of(new routerActions.Navigate({ url: '/settings/miscellaneous' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_MISCELLANEOUS_ITEM, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() deleteMiscellaneousItem$ = this.actions$
    .pipe(
      ofType<SettingsActions.DeleteMiscellaneousItem>(SettingsActions.DELETE_MISCELLANEOUS_ITEM),
      mergeMap(({ id }) => {
        return concat(
          of(new QueryActions.QueryInProgress(DELETE_MISCELLANEOUS_ITEM)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .deleteMiscellaneousItem(id)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(DELETE_MISCELLANEOUS_ITEM, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.miscellaneous.deleteSuccess')),
                of(new routerActions.Navigate({ url: '/settings/miscellaneous' })),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(DELETE_MISCELLANEOUS_ITEM, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  /************* CALENDAR SETTING **************/
  @Effect() GetCenterHolidays$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetCenterHolidays>(SettingsActions.GET_CENTER_HOLIDAYS),
      mergeMap(({ queryParams }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_CENTER_HOLIDAYS)),
        this.settingsRepository
          .getCenterHolidays(queryParams)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_CENTER_HOLIDAYS, data.events)),
              of(new SettingsActions.GetCenterHolidaysSuccess(data.events)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_CENTER_HOLIDAYS, error)),
            )),
          ),
      )),
    );

  @Effect() FetchCenterHolidays$ = this.actions$
    .pipe(
      ofType<SettingsActions.FetchCenterHolidays>(SettingsActions.FETCH_CENTER_HOLIDAYS),
      mergeMap(({ queryParams }) => concat(
        of(new QueryActions.QueryInProgress(GET_CENTER_HOLIDAYS)),
        this.settingsRepository
          .getCenterHolidays(queryParams)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_CENTER_HOLIDAYS, data.events)),
              of(new SettingsActions.GetCenterHolidaysSuccess(data.events)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_CENTER_HOLIDAYS, error)),
            )),
          ),
      )),
    );

  /** END CALENDAR SETTING */

    @Effect() postClassroom$ = this.actions$
    .pipe(
      ofType<SettingsActions.CreateClassroom>(SettingsActions.POST_CLASSROOM),
      mergeMap(({ data }) => {
        return concat(
        of(new QueryActions.QueryInProgress(POST_CLASSROOM)),
        of(this.spinnerService.start()),
        this.settingsRepository
          .postClassroom(data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(POST_CLASSROOM, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.createClassroomSuccess')),
              of(new routerActions.Navigate({ url: '/settings/location' })),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_CLASSROOM, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

    @Effect() putClassroom$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateClassroom>(SettingsActions.PUT_CLASSROOM),
      mergeMap(({ data }) => {
        return concat(
        of(new QueryActions.QueryInProgress(PUT_CLASSROOM)),
        of(this.spinnerService.start()),
        this.settingsRepository
          .putClassroom(data.id, data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(PUT_CLASSROOM, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.updateClassroomSuccess')),
              of(new routerActions.Navigate({ url: '/settings/location' })),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(PUT_CLASSROOM, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

    @Effect() deleteClassroom$ = this.actions$
    .pipe(
      ofType<SettingsActions.DeleteClassroom>(SettingsActions.DELETE_CLASSROOM),
      mergeMap(({ id }) => {
        return concat(
        of(new QueryActions.QueryInProgress(DELETE_CLASSROOM)),
        of(this.spinnerService.start()),
        this.settingsRepository
          .deleteClassroom(id)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_CLASSROOM, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.deleteClassroomSuccess')),
              of(new routerActions.Navigate({ url: '/settings/location' })),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(DELETE_CLASSROOM, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

    @Effect() putPrimaryLocation$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdatePrimaryLocation>(SettingsActions.PUT_PRIMARY_LOCATION),
      mergeMap(({ data, queryParams }) => {
        return concat(
        of(new QueryActions.QueryInProgress(PUT_PRIMARY_LOCATION)),
        of(this.spinnerService.start()),
        this.settingsRepository
          .updatePrimaryLocation(data.id, data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(PUT_PRIMARY_LOCATION, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.saveSuccess')),
              of(this.spinnerService.stop()),
              of(new AuthActions.UpdatePrimaryLocationOnProfile(data)),
              of(new routerActions.Navigate({ url: '/settings/location', queryParams })),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(PUT_PRIMARY_LOCATION, error)),
              of(this.spinnerService.stop()),
              of(new routerActions.Navigate({ url: '/settings/location', queryParams })),
            )),
          ),
        );
      }),
    );

    @Effect() getNotificationSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetNotificationSetting>(SettingsActions.GET_NOTIFICATION_SETTING),
      mergeMap(() => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_NOTIFICATION_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getNotificationSetting()
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_NOTIFICATION_SETTING, data)),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_NOTIFICATION_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() getTeacherStaffSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetTeacherStaffSetting>(SettingsActions.GET_TEACHER_STAFF_SETTING),
      mergeMap(() => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_TEACHER_STAFF_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getTeacherStaffSetting()
            .pipe(
              mergeMap(data => {
                 return  concat(
                    of(new QueryActions.QuerySuccess(GET_TEACHER_STAFF_SETTING, data)),
                    of(this.spinnerService.stop()),
                  );
                }
              ),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_TEACHER_STAFF_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

    @Effect() postEmailTemplate$ = this.actions$
    .pipe(
      ofType<SettingsActions.CreateEmailTemplate>(SettingsActions.POST_EMAIL_TEMPLATE),
      mergeMap(({ data }) => {
        return concat(
        of(new QueryActions.QueryInProgress(POST_EMAIL_TEMPLATE)),
        of(this.spinnerService.start()),
        this.settingsRepository
          .postEmailTemplate(data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(POST_EMAIL_TEMPLATE, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.emailTemplate.createSuccess')),
              of(this.spinnerService.stop()),
              of(new settingsActions.SetEmailTemplateValue({...resp}))
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_EMAIL_TEMPLATE, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

    @Effect() putEmailTemplate$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateEmailTemplate>(SettingsActions.PUT_EMAIL_TEMPLATE),
      mergeMap(({ data, id }) => {
        return concat(
        of(new QueryActions.QueryInProgress(PUT_EMAIL_TEMPLATE)),
        of(this.spinnerService.start()),
        this.settingsRepository
          .putEmailTemplate(id, data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(PUT_EMAIL_TEMPLATE, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.emailTemplate.updateSuccess')),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(PUT_EMAIL_TEMPLATE, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

    @Effect() getReminderSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetReminderSetting>(SettingsActions.GET_REMINDER_SETTING),
      mergeMap(() => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_REMINDER_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getReminderSetting()
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_REMINDER_SETTING, data)),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_REMINDER_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() getProfileDynamicFieldSettings$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetProfileDynamicFieldSettings>(SettingsActions.GET_PROFILE_DYNAMIC_FIELD_SETTINGS),
      mergeMap((query) => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_PROFILE_DYNAMIC_FIELD_SETTINGS)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getProfileDynamicFieldSettings(query)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_PROFILE_DYNAMIC_FIELD_SETTINGS, data)),
                of(this.spinnerService.stop()),
                of(new SettingsActions.GetProfileDynamicFieldSettingsSuccess(data)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_PROFILE_DYNAMIC_FIELD_SETTINGS, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() fetchStudentParentSetting$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'settings/student-parent')),
      mergeMap(() => concat(
        of(new SettingsActions.GetStudentParentSetting())
      )),
    );

  @Effect() fetchOnlineRegistrationSetting$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'settings/online-registration')),
      mergeMap(() => concat(
        of(new SettingsActions.GetStudentParentSetting())
      )),
    );

  @Effect() getStudentParentSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetStudentParentSetting>(SettingsActions.GET_STUDENT_PARENT_SETTING),
      mergeMap(() => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_STUDENT_PARENT_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .getStudentParentSetting()
            .pipe(
              mergeMap(data => {
                  return  concat(
                    of(new QueryActions.QuerySuccess(GET_STUDENT_PARENT_SETTING, data)),
                    of(this.spinnerService.stop()),
                  );
                }
              ),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_STUDENT_PARENT_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() putStudentParentSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateStudentParentSetting>(SettingsActions.PUT_STUDENT_PARENT_SETTING),
      mergeMap(({ data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_STUDENT_PARENT_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .putStudentParentSetting(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_STUDENT_PARENT_SETTING, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.saveSuccess')),
                of(this.spinnerService.stop()),
                of(new routerActions.Navigate({ url: '/settings/student-parent' })),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_STUDENT_PARENT_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() putOnlineRegistrationSetting$ = this.actions$
    .pipe(
      ofType<SettingsActions.UpdateOnlineRegistrationSetting>(SettingsActions.PUT_ONLINE_REGISTRATION_SETTING),
      mergeMap(({ data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_ONLINE_REIGSTRATION_SETTING)),
          of(this.spinnerService.start()),
          this.settingsRepository
            .putStudentParentSetting(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_ONLINE_REIGSTRATION_SETTING, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.saveSuccess')),
                of(this.spinnerService.stop()),
                of(new routerActions.Navigate({ url: '/settings/online-registration' })),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_ONLINE_REIGSTRATION_SETTING, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() getPreviewPdf$ = this.actions$
    .pipe(
      ofType<SettingsActions.GetPreviewPdf>(SettingsActions.GET_PREVIEW_PDF),
      mergeMap(({locationId}) => {
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(GET_PREVIEW_PDF)),
          this.settingsRepository
            .getPreviewPdf(locationId)
            .pipe(
              mergeMap((data: Blob) => {
                const pdfBlob = new Blob([data], {type: 'application/pdf'});
                const pdfUrl = URL.createObjectURL(pdfBlob);

                window.open(pdfUrl, '_blank');

                return concat(
                  of(new QueryActions.QuerySuccess(GET_PREVIEW_PDF, pdfUrl)),
                  of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.previewPdf.success')),
                  of(this.spinnerService.stop()),
                );
              }),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_PREVIEW_PDF, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );
}
