import {get} from 'lodash';
import {AbstractControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Student} from '../app/+student/model';
import {Parent} from '../app/+parent/model';
import {Lesson} from '../app/+lesson/model';
import {InvoiceMiscellaneousItem} from '../app/+payment/model';
import {National} from '../app/+settings/model/settings.model';
import {StaffMember} from '../app/shared/model/staff-member.model';

type OptionsList = Student[] | StaffMember[] | Parent[] | Lesson[] | InvoiceMiscellaneousItem[] | National[];

export const searchItemsFilterForNational = (options, value: string) => {
  return options
    .filter((item) => !!item)
    .filter((item) => item.countryName.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0);
};

export const searchItemsFilterForCurrency = (options, value: string) => {
  return options
    .filter((item) => !!item)
    .filter((item) =>
      item.countryName.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0 ||
      item.currencyCode.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0
    );
};

export const searchItemsFilterForCountry = (options, value: string) => {
  return options
    .filter((item) => !!item)
    .filter((item) =>
      item.countryName.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0 ||
      item.countryCode.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0
    );
};

export const searchItemsFilterForTimeZone = (options, value: string) => {
  return options
    .filter((item) => !!item)
    .filter((item) =>
      item.countryName.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0 ||
      item.timezone.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0
    );
};

export const searchValues = (field: AbstractControl, clearAfterSelect = false) => {
  return field.valueChanges
    .pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map(
        option =>
          option && typeof option === 'object'
            ? ((get(option, 'profile') && !clearAfterSelect) ? `${option.profile.firstName} ${option.profile.lastName}` : option.name)
            : option
      ),
      debounceTime(600),
      distinctUntilChanged(),
    );
};

export const searchValuesV2 = (observable: Observable<any>, clearAfterSelect = false) => {
  return observable.pipe(
    map(
      option =>
        option && typeof option === 'object'
          ? ((get(option, 'profile') && !clearAfterSelect) ? `${option.profile.firstName} ${option.profile.lastName}` : option.name)
          : option
    ),
    debounceTime(600),
    distinctUntilChanged(),
  );
};

export const searchItems = (field: AbstractControl, clearAfterSelect = false) => {
  return field.valueChanges
    .pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      distinctUntilChanged(),
      debounceTime(600),
      map(
        option =>
          option && typeof option === 'object'
            ? ((get(option, 'name') && !clearAfterSelect) ? option.name : '')
            : option
      ),
    );
};

export const searchFilter = (options, value: string) => {
  return options
    .filter(({ profile }) => !!profile)
    .filter(({ profile }) => profile.firstName.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0);
};
export const searchItemsFilter = (options, value: string) => {
  return options
    .filter((item) => !!item)
    .filter((item) => item.name.toLowerCase().indexOf(!!value ? value.toLowerCase() : '') === 0);
};

export const getFilteredData = (filteredData: Observable<any>, data: OptionsList, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchFilter(data, value)
        : options.slice()
      ),
    );
};

export const getFilteredItems = (filteredData: Observable<any>, data: OptionsList, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchItemsFilter(data, value)
        : options.slice()
      ),
    );
};

export const onChangeClear = (field: AbstractControl, item: any) => {
  if (item) {
    field.setValue(item);
    return;
  }

  if (!get(field, 'value.id')) {
    field.setValue(null);
  }
};

export const displayOptionName = (option: StaffMember | Student | Parent) => {
  return get(option, 'profile')
    ? `${option.profile.firstName} ${option.profile.lastName}`
    : null;
};

export const getFilteredItemsForNational = (filteredData: Observable<any>, data: OptionsList, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchItemsFilterForNational(data, value)
        : options.slice()
      ),
    );
};

export const getFilteredItemsForClassroom = (filteredData: Observable<any>, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchItemsFilter(options, value)
        : options.slice()
      ),
    );
};

export const getFilteredItemsForCurrency = (filteredData: Observable<any>, data: OptionsList, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchItemsFilterForCurrency(data, value)
        : options.slice()
      ),
    );
};

export const getFilteredItemsForCountry = (filteredData: Observable<any>, data: OptionsList, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchItemsFilterForCountry(data, value)
        : options.slice()
      ),
    );
};

export const getFilteredItemsForTimeZone = (filteredData: Observable<any>, data: OptionsList, value: string) => {
  return filteredData
    .pipe(
      map(options => options
        ? searchItemsFilterForTimeZone(data, value)
        : options.slice()
      ),
    );
};

export const formatTooLongLine = (text: string, maxCharacterALine: number) => {
  if (!text) {
    return text;
  }

  if (text.length < maxCharacterALine) {
    return text;
  }

  let firstLine = '';
  let secondLine = '';

  for (let i = maxCharacterALine; i > 0; i--) {
    let lastBlankSpaceInFirstLineIndex = maxCharacterALine;
    if (text[i] === ' ') {
      lastBlankSpaceInFirstLineIndex = i;
      firstLine = text.slice(0, lastBlankSpaceInFirstLineIndex);
      secondLine = text.slice(lastBlankSpaceInFirstLineIndex, text.length);
    }
  }
  return `${firstLine} \n ${secondLine}`;
};
