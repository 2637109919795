import { Query } from '../../../store/query';
import {
  Settings,
  Location,
  Locations,
  LessonSetting,
  LessonSettings,
  MiscellaneousItem,
  MiscellaneousItems,
  Classroom,
  EmailTemplate,
  ProfileDynamicFieldSettings
} from '../../model/settings.model';
import { CenterHolidays } from '../../model/calendar.model';

export const GET_SETTINGS = 'getSettingsQuery';
export const PUT_SETTINGS = 'putSettingsQuery';

export const GET_LOCATIONS = 'getLocationsQuery';
export const POST_LOCATION = 'settingsPostLocationQuery';
export const PUT_LOCATION = 'putLocationQuery';
export const DELETE_LOCATION = 'deleteLocationQuery';

export const GET_LESSON_SETTINGS = 'getLessonSettingsQuery';
export const POST_LESSON_SETTING = 'postLessonSettingQuery';
export const PUT_LESSON_SETTING = 'putLessonSettingQuery';
export const DELETE_LESSON_SETTINGS = 'deleteLessonSettingsQuery';

export const GET_MISCELLANEOUS_ITEMS = 'getMiscellaneousItemsQuery';
export const POST_MISCELLANEOUS_ITEM = 'postMiscellaneousItemQuery';
export const PUT_MISCELLANEOUS_ITEM = 'putMiscellaneousItemQuery';
export const DELETE_MISCELLANEOUS_ITEM = 'deleteMiscellaneousItemQuery';

export const GET_CENTER_HOLIDAYS = 'getCenterHolidaysQuery';

export const POST_CLASSROOM = 'postClassroomQuery';
export const PUT_CLASSROOM = 'putClassroomQuery';
export const DELETE_CLASSROOM = 'deleteClassroomQuery';

export const PUT_PRIMARY_LOCATION = 'putPrimaryLocationQuery';
export const GET_NOTIFICATION_SETTING = 'getNotificationQuery';

export const SET_EMAIL_TEMPLATE_VALUE = 'setEmailTemplateValue';
export const POST_EMAIL_TEMPLATE = 'postEmailTemplate';
export const PUT_EMAIL_TEMPLATE = 'putEmailTemplate';

export const GET_REMINDER_SETTING = 'getReminderQuery';

export const GET_TEACHER_STAFF_SETTING = 'getTeacherStaffQuery';

export const GET_PROFILE_DYNAMIC_FIELD_SETTINGS = 'getProfileDynamicFieldSettingsQuery';

export const GET_STUDENT_PARENT_SETTING = 'getStudentParentQuery';
export const PUT_STUDENT_PARENT_SETTING = 'putStudentParentQuery';
export const PUT_ONLINE_REIGSTRATION_SETTING = 'putOnlineRegistrationQuery';
export const GET_PREVIEW_PDF = 'getPreviewPdfQuery';

export interface SettingsState {
  getSettingsQuery: Query<Settings>;
  putSettingsQuery: Query<Settings>;
  settings?: Settings;

  locations?: Locations;
  getLocationsQuery: Query<Locations>;
  settingsPostLocationQuery: Query<Location>;
  putLocationQuery: Query<Location>;
  deleteLocationQuery: Query<Location>;

  lessonSettings?: LessonSettings;
  getLessonSettingsQuery: Query<LessonSettings>;
  postLessonSettingQuery: Query<LessonSetting>;
  putLessonSettingQuery: Query<LessonSetting>;
  deleteLessonSettingsQuery: Query<LessonSetting>;

  getMiscellaneousItemsQuery: Query<MiscellaneousItems>;
  postMiscellaneousItemQuery: Query<MiscellaneousItem>;
  putMiscellaneousItemQuery: Query<MiscellaneousItem>;
  deleteMiscellaneousItemQuery: Query<MiscellaneousItem>;
  miscellaneousItems?: MiscellaneousItems;

  getCenterHolidaysQuery: Query<CenterHolidays>;
  centerHolidays?: CenterHolidays;

  postClassroomQuery: Query<Classroom>;
  putClassroomQuery: Query<Classroom>;
  deleteClassroomQuery: Query<Classroom>;
  putPrimaryLocationQuery: Query<Location>;
  getNotificationQuery: Query<any>;

  postEmailTemplate: Query<EmailTemplate>;
  putEmailTemplate: Query<EmailTemplate>;
  emailTemplate: EmailTemplate;
  getReminderQuery: Query<any>;

  getTeacherStaffQuery: Query<any>;

  getProfileDynamicFieldSettingsQuery: Query<ProfileDynamicFieldSettings>;
  profileDynamicFieldSettings?: ProfileDynamicFieldSettings;

  getStudentParentQuery: Query<any>;
  putStudentParentQuery: Query<any>;
  putOnlineRegistrationQuery: Query<any>;
  getPreviewPdfQuery: Query<any>;

}

export const initialState: SettingsState = {
  getSettingsQuery: {},
  putSettingsQuery: {},
  getLocationsQuery: {},
  settingsPostLocationQuery: {},
  putLocationQuery: {},
  deleteLocationQuery: {},
  getLessonSettingsQuery: {},
  postLessonSettingQuery: {},
  putLessonSettingQuery: {},
  deleteLessonSettingsQuery: {},
  getMiscellaneousItemsQuery: {},
  postMiscellaneousItemQuery: {},
  putMiscellaneousItemQuery: {},
  deleteMiscellaneousItemQuery: {},
  getCenterHolidaysQuery: {},
  postClassroomQuery: {},
  putClassroomQuery: {},
  deleteClassroomQuery: {},
  putPrimaryLocationQuery: {},
  getNotificationQuery: {},
  postEmailTemplate: {},
  putEmailTemplate: {},
  emailTemplate: {
    color: '#237096',
    facebook: null,
    instagram: null,
    youtube: null,
    tiktok: null,
    twitter: null,
    snapchat: null,
    whatsapp: null,
    linkedin: null,
    footerContent: null,
    id: null
  },
  getReminderQuery: {},
  getTeacherStaffQuery: {},
  getProfileDynamicFieldSettingsQuery: {},
  getStudentParentQuery: {},
  putStudentParentQuery: {},
  putOnlineRegistrationQuery: {},
  getPreviewPdfQuery: {}
};
