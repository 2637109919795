import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject ,  Observable } from 'rxjs';
import { map, takeUntil, distinctUntilChanged, filter, take, debounceTime } from 'rxjs/operators';
import { get, reduce, findIndex, forEach, cloneDeep } from 'lodash';

import {
  getTimelineDayEvent,
  getTeacherViewEvent,
  getClassroomViewEvent,
  getCalendarEvent,
  getSecondaryTeacherViewEvent,
  mapLocationToClassroomResources
} from './calendar.utils';
import { State, selectors } from '../../../store';
import { hasQuerySucceeded, isQueryInProgress } from '../../../store/query';
import {StaffMember} from '../../../shared/model/staff-member.model';
import {hasRoles} from '../../user/shared/staff-member.utils';
import {StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';

@Injectable()
export class CalendarService {
  private unsubscribe$ = new Subject();

  constructor(private store: Store<State>) { }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getHistory() {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ logList }) => logList)
      );
  }

  getCopiedLesson() {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ copiedLesson }) => copiedLesson)
      );
  }

  getLockStatus() {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ locked }) => locked),
        distinctUntilChanged()
      );
  }

  getLessonsQueryIsSuccess() {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(300),
        distinctUntilChanged(),
        map(({ getLessonsQuery }) => {
          return hasQuerySucceeded(getLessonsQuery);
        })
      );
  }

  getLessonsQueryIsInProgress() {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(300),
        distinctUntilChanged(),
        map(({ getLessonsQuery }) => isQueryInProgress(getLessonsQuery))
      );
  }

  // get events for calendar
  getTimelineDayEvents(): Observable<Array<any>> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) => lessons.data
          .map(event => {
            return getTimelineDayEvent(event);
          })),
        take(1)
      );
  }

  getFullCalendarEvents(modeView): Observable<Array<any>> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) => {
          // create arrray merge event
          let arrayMerge = [...lessons.data] as [];
          const renderEvent = [];
          const objectEvent = {};
          // create lesson to add event
          if (lessons.data && lessons.data.length > 0 && modeView === 'dayGridMonth') {
            const temp = lessons.data[0];
            for (const key in temp) {
              // check also if property is not inherited from prototype
              if (temp.hasOwnProperty(key)) {
                switch (typeof temp[key]) {
                  case 'object':
                    if (temp[key] === []) {
                      objectEvent[key] = [];
                    } else {
                      if (temp[key] === null) {
                        temp[key] = null;
                      } else {
                        objectEvent[key] = {};
                      }
                    }
                    break;
                  case 'string':
                    objectEvent[key] = '';
                    break;
                  case 'number':
                    objectEvent[key] = 0;
                    break;
                }
              }
            }
            // variable to make start and end of eventRender in a day
            const timedifference = -(new Date().getTimezoneOffset() / 60);
            // crete array lessons event
            forEach(lessons['seeMore'], item => {
              for (let i = 0; i < item.more; i++) {
                objectEvent['start'] = item.day + `T${23 - timedifference}:24:33+00:00`;
                objectEvent['end'] = item.day + `T${23 - timedifference}:28:33+00:00`;
                objectEvent['title'] = 'eventRender';
                renderEvent.push(cloneDeep(objectEvent));
              }
            });
            arrayMerge = [...lessons.data, ...renderEvent] as [];
          }
          if (lessons.data && lessons.data.length > 0 && (modeView === 'timeGridWeek' || modeView === 'dayGridWeek')) {
             return lessons.data.map(event => {
              return getCalendarEvent(event, modeView);
            });
          }
          return arrayMerge
          .map(event => {
            return getCalendarEvent(event, modeView);
          });
        }),
        take(1)
      );
  }

  getLessonsByTeacher(): Observable<any> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) =>
          reduce(lessons.data, (result, lesson, key) => {
            result.events.push(
              getTeacherViewEvent(lesson)
            );
            const i = findIndex(result.resources, (teacher) => teacher.id === get(lesson, 'teacher.id', 'empty'));
            if (i === -1) {
              result.resources.push({
                id: get(lesson, 'teacher.id', 'empty'),
                title: lesson.teacher ? `${lesson.teacher.profile.firstName.trim()} ${lesson.teacher.profile.lastName.trim()}` : '-',
                extendedProps: {
                  teacher: lesson.teacher
                }
              });
            }
            return result;
          },
          { resources: [], events: [] })
        ),
        take(1)
      );
  }

  getLessonsBySecondaryTeacher(): Observable<any> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) =>
          reduce(lessons.data, (result, lesson, key) => {
            result.events.push(
              getSecondaryTeacherViewEvent(lesson)
            );
            const i = findIndex(result.resources, (teacher) => teacher.id === get(lesson, 'secondaryTeacher.id', 'empty'));
            if (i === -1 && lesson.secondaryTeacher) {
              result.resources.push({
                id: get(lesson, 'secondaryTeacher.id'),
                // tslint:disable-next-line: max-line-length
                title: `${lesson.secondaryTeacher.profile.firstName.trim()} ${lesson.secondaryTeacher.profile.lastName.trim()}`,
                extendedProps: {
                  secondaryTeacher: lesson.secondaryTeacher
                }
              });
            }
            // if (i === -1 && !lesson.secondaryTeacher) {
            //   result.resources.unshift({
            //     id: 'empty',
            //     title: '-',
            //     extendedProps: {
            //       secondaryTeacher: null
            //     }
            //   });
            // }
            return result;
          },
          { resources: [], events: [] })
        ),
        take(1)
      );
  }
  getLessonsBySecondaryAndPrimaryTeacher(): Observable<any> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) =>
          reduce(lessons.data, (result, lesson, key) => {
            result.events.push(
              getSecondaryTeacherViewEvent(lesson)
            );
            result.events.push(
              getTeacherViewEvent(lesson)
            );
            // Add teacher primary
            const i = findIndex(result.resources, (teacher) => teacher.id === get(lesson, 'teacher.id', 'empty'));
            if (i === -1) {
              result.resources.push({
                id: get(lesson, 'teacher.id', 'empty'),
                title: lesson.teacher ? `${lesson.teacher.profile.firstName.trim()} ${lesson.teacher.profile.lastName.trim()}` : '-',
                extendedProps: {
                  teacher: lesson.teacher
                }
              });
            }
            // Add teacher secondary
            const j = findIndex(result.resources, (teacher) => teacher.id === get(lesson, 'secondaryTeacher.id', 'empty'));
            if (j === -1 && lesson.secondaryTeacher) {
              result.resources.push({
                id: get(lesson, 'secondaryTeacher.id'),
                // tslint:disable-next-line: max-line-length
                title: `${lesson.secondaryTeacher.profile.firstName.trim()} ${lesson.secondaryTeacher.profile.lastName.trim()}`,
                extendedProps: {
                  secondaryTeacher: lesson.secondaryTeacher
                }
              });
            }
            return result;
          },
          { resources: [], events: [] })
        ),
        take(1)
      );
  }
  getLessonsByClassroom(): Observable<any> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) =>
          reduce(lessons.data, (result, lesson, key) => {
            result.events.push(
              getClassroomViewEvent(lesson)
            );
            const i = findIndex(result.resources, (classroom) => classroom.id === get(lesson, 'classroom.id', 'unknown'));
            if (i < 0) {
              const location = {
                ...lesson.location,
                children: [
                  lesson.classRoom
                ]
              };
              result.resources = result.resources.concat(
                mapLocationToClassroomResources(location)
              );
            }
            return result;
          },
          { resources: [], events: [] })
        ),
        take(1)
      );
  }

  getOriginalLesson() {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ originalLesson }) => !!originalLesson),
        map(({ originalLesson }) => originalLesson),
        take(1)
      );
  }

  getLessons(): Observable<any> {
    return this.store
      .select(selectors.selectCalendar)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ lessons }) => !!lessons),
        map(({ lessons }) => lessons),
        take(1)
      );
  }

  getLocationIdForCalendarNavigate(staffMember: StaffMember, logonLocation) {
    const isLogonLocationDefined = !!logonLocation;
    const logonLocationRequired = get(staffMember, 'learningCenter.logonLocationRequired', false);
    const logonLocationCalendarFilterApplied = get(staffMember, 'learningCenter.logonLocationCalendarFilterApplied', true);
    const isOnlyTeacherAccount = hasRoles(staffMember, StaffMemberRoleEnum.TEACHER);

    if ((!isLogonLocationDefined && logonLocationCalendarFilterApplied) && logonLocationRequired && !isOnlyTeacherAccount) {
      return null;
    }

    let classRoomParentId = null;
    const locations = get(staffMember, 'locations', []);

    if (isOnlyTeacherAccount) {
      classRoomParentId = locations.map(location => location.location.id);
    } else if (logonLocationRequired && logonLocationCalendarFilterApplied && isLogonLocationDefined) {
      classRoomParentId = logonLocation ? [logonLocation.id] : null;
    } else {
      if (hasRoles(staffMember, StaffMemberRoleEnum.LEARNING_CENTER_ADMIN)) {
        classRoomParentId = get(staffMember, 'learningCenter.primaryLocation.id');
      } else if (Array.isArray(locations) && locations.length) {
        classRoomParentId = locations.map(location => location.location.id);
      }
    }

    return classRoomParentId;
  }
}
