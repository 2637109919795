import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-dialog-price-changed',
  templateUrl: 'dialog-price-changed.component.html',
  styleUrls: ['./dialog-price-changed.component.scss'],
})
export class DialogPriceChangedComponent {
  showDialog: boolean;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  @Input()
  get isActive() {
    return this.showDialog;
  }

  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor() {
  }

  closeDialog() {
    this.showDialog = false;
    this.isActiveChange.emit(this.showDialog);
  }

  onConfirm() {
    this.confirm.emit();
    this.showDialog = false;
    this.isActiveChange.emit(this.showDialog);
  }
}
