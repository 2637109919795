
import { throwError,  Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {isEmpty} from 'lodash';
import { environment } from '../../../../environments/environment';
import {LoginCredentials, AuthData, AuthResponse, PasswordData, RequestForResetData, LogonLocationData} from '../model';
import { authReposnseParser } from './auth.factory';
import {StaffMember} from '../../../shared/model/staff-member.model';

@Injectable()
export class AuthRepository {
  constructor(private http: HttpClient) { }

  setPassword(passwordData: PasswordData) {
    return this.http
      .post(`${environment.apiUrl}/api/users/set_password`, passwordData)
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  requestForReset(requestForResetData: RequestForResetData) {
    return this.http
      .post(`${environment.apiUrl}/api/users/reset_password/send_email`, requestForResetData)
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  authorize(credentials: LoginCredentials): Observable<AuthData> {
    return this.http
      .post(`${environment.apiUrl}/api/login_check`, credentials)
      .pipe(
        map((data: AuthResponse) => authReposnseParser(data)),
      );
  }

  reauthorize(refreshToken: string): Observable<AuthData> {
    return this.http
      .post(`${environment.apiUrl}/api/token/refresh`, {
        refresh_token: refreshToken,
      })
      .pipe(
        map((data: AuthResponse) => authReposnseParser(data)),
      );
  }

  /**
   * Return a linkedStaffMember who is a StaffMember links with current user
   */
  getMe(): Observable<StaffMember> {
    return this.http
      .get(`${environment.apiUrl}/api/users/me`)
      .pipe(catchError((error: Response) => throwError(error || 'Server error')));
  }

  verifyToken(token: string): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/users/validate/confirmationToken`, {
        confirmationToken: token,
      })
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  syncLogLogout(): Observable<any> {
    return new Observable<never>(subscriber => subscriber.complete());
    /*return this.http
      .post(`${environment.apiUrl}/api/simple_audits/log`, {
        actionType: 'user_sign_out',
      })
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );*/
  }

  syncLog(log): Observable<any> {
    return new Observable<never>(subscriber => subscriber.complete());
    /*return this.http
      .post(`${environment.apiUrl}/api/simple_audits/activities_log`, {
        actions: log,
      })
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );*/
  }

  getLogonLocationInfo(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/logon_location/info`)
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  registerLogonLocation(logonLocation: LogonLocationData): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/logon_location/register`, logonLocation)
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  registerLogonLocationWithToken(logonLocation: LogonLocationData, token?: string): Observable<any> {
    if (isEmpty(token)) {
      return this.registerLogonLocation(logonLocation);
    }

    const headers = new HttpHeaders();
    headers.set('Authorization', `Bearer ${token}`);

    return this.http
      .post(
        `${environment.apiUrl}/api/logon_location/register`,
        logonLocation,
        {headers}
      )
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  revokeLogonLocation(): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/logon_location/revoke`, {})
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }

  changeLogonLocation(logonLocation: LogonLocationData): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/api/logon_location/change`, logonLocation)
      .pipe(
        catchError((error: Response) => throwError(error || 'Server error'))
      );
  }
}
