import {Component, OnChanges, Input, Output, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import { Location } from '../../model/settings.model';

import { forEach } from 'lodash';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { Subject } from 'rxjs';
import * as SettingActions from '../../store/settings/settings.actions';
import { SettingsService } from '../../shared/settings.service';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'e-setting-location-box',
  templateUrl: './setting-location-box.component.html',
  styleUrls: ['./setting-location-box.component.scss']
})
export class SettingLocationBoxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() locations: Location[] = [];
  @Input() pending: boolean;
  @Output() selectedLocations: EventEmitter<Location[]> = new EventEmitter();
  @Output() editLocation: EventEmitter<Location> = new EventEmitter();
  @Output() removeLocation: EventEmitter<string> = new EventEmitter();
  @Output() addClassroomEmit: EventEmitter<string> = new EventEmitter();
  @Output() viewClassroomEmit: EventEmitter<Location> = new EventEmitter();
  @Output() previewInvoicePdf: EventEmitter<string> = new EventEmitter();

  dataSource = new MatTableDataSource();
  canDelete: boolean;
  selection = new SelectionModel<Location>(true, []);
  unsubscribe$ = new Subject();
  displayedColumns = ['name', 'symbol', 'address', 'phone', 'note', 'primaryLocation', 'classroom', 'actionsClassroom', 'actions'];
  queryParams: any;

  constructor(
    private store: Store<State>,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.queryParams = this.activatedRoute.snapshot.queryParams;
      });
  }

  ngOnChanges() {
    this.dataSource.data = [];
    this.dataSource.data = this.locations;
    this.selection.clear();

    setTimeout(() => this.onUpdateLocations(), 0);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  onUpdateLocations() {
    this.selectedLocations.emit(this.selection.selected);
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : forEach(this.dataSource.data, (el) => {
        this.selection.select(el);
      });

    this.onUpdateLocations();
  }

  toggleSelect(event: Location) {
    this.selection.toggle(event);

    this.onUpdateLocations();
  }

  deleteItem(id: string) {
    if (id) { this.removeLocation.emit(id); }
  }

  editItem(event: Location) {
    this.editLocation.emit(event);
  }

  isEmptyResult() {
    return !this.locations || !this.locations.length;
  }

  addClassroom(id: string) {
    this.addClassroomEmit.emit(id);
  }

  ViewClassRoom(location: Location) {
    this.viewClassroomEmit.emit(location);
  }

  changePrimary(location: Location) {
    location.primaryLocation = !location.primaryLocation;
    this.store.dispatch(new SettingActions.UpdatePrimaryLocation(location.id, location, this.queryParams));
    // this.settingsService.getPrimaryLocation().subscribe(data => {
    //   if(data){
    //     this.locations.forEach(x => {
    //       if(x.id == data.id) return;
    //       else x.primaryLocation = false;
    //     })
    //   }
    //   else{
    //     location.primaryLocation = !location.primaryLocation;
    //   }
    // });
  }

  ngOnDestroy() {
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  previewInvoicePdfForItem(event: Location) {
    this.previewInvoicePdf.emit(event.id);
  }
}
