import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {SettingsRepository} from '../../../../+settings/shared/settings.repository';
import {takeWhileInclusive} from 'rxjs-take-while-inclusive';
import {onChangeClear} from '../../../../../utils';
import {findIndex, filter, isEmpty, has} from 'lodash';
import {Location} from '../../../../+settings/model/settings.model';
import {UIService} from '../../../../services/ui.service';

@Component({
  selector: 'e-select-location-multiple-chip-list',
  templateUrl: './select-location-multiple-chip-list.component.html',
  styleUrls: ['./select-location-multiple-chip-list.component.scss']
})
export class SelectLocationMultipleChipListComponent implements OnInit, OnChanges {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() locationsIdArrCheckClearFilter: [];
  @Input() logonLocationIdSelectedOnLogin = '';
  @Output() onLocationsChange: EventEmitter<any> = new EventEmitter();
  data: Location[];
  subject = new Subject();
  nextPage$ = new Subject();
  locations: any = [];
  tempData: any;

  constructor(
    private settingsRepository: SettingsRepository,
    private activatedRoute: ActivatedRoute,
    private uiService: UIService,
  ) {
  }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(250),
      distinctUntilChanged((prev, curr) => {
        if (curr === 'reload') {
          return false;
        }

        return prev === curr;
      }),
    ).pipe(
      switchMap((trigger) => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
          map(data => ({ trigger, data }))
        );
      }),
      startWith({ trigger: 'initial', data: [{ loading: true }] })
    ).subscribe(({ trigger, data }) => {
      this.tempData = data;
      const queryParams = this.activatedRoute.snapshot.queryParams;

      if (trigger === 'reload') {
        this.locations = [];
      }

      if (queryParams['classRoom.parent.id'] && data.length && data[0] && !data[0].loading) {
        data.forEach(x => {
            if (queryParams['classRoom.parent.id'].includes(x.id)) {
              this.locations.push({
                locationId: x.id,
                name: x.name
              });
            }
          }
        );
      }
      //
      this.sentDataOutside();
    });

    //
    this.loadDataFromQueryParam();
    //
    this.uiService.onRefresh.subscribe(() => {
      // fix load primary location when Select Centre
      const queryParams = this.activatedRoute.snapshot.queryParams;
      if (queryParams['classRoom.parent.id'] && this.tempData.length && !this.locations.length) {
        this.tempData.forEach(x => {
            if (queryParams['classRoom.parent.id'].includes(x.id)) {
              this.locations.push({
                locationId: x.id,
                name: x.name
              });
            }
          }
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check locations from parent (calendar), if empty , clear locations
    if (isEmpty(this.locationsIdArrCheckClearFilter)) {
      this.locations = [];
    }
    // Set value logon location selected on login.
    if (has(changes, 'logonLocationIdSelectedOnLogin')) {
      // first time select logon location
      if (changes.logonLocationIdSelectedOnLogin.previousValue !== changes.logonLocationIdSelectedOnLogin.currentValue) {
        this.reloadDataFromQueryParam();
      }
    }
  }

  fetchData(page: number = 1) {
    if (!this.data) {
      return this.settingsRepository
        .getLocations({
          page: page.toString(),
          itemsPerPage: '1000' // put in string '1000' for fix parse params error itemsPerPage:1000
        })
        .pipe(
          map(({data}) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onLocationSelectionChange = (item: any, field?: AbstractControl) => {
    const i = findIndex(this.locations, (l) => l.locationId === item.id);
    if (i === -1) {
      this.locations.push({
        locationId: item.id,
        name: item.name
      });
    }
    // clear input
    if (field) {
      onChangeClear(field, ' ');
    }
    //
    this.sentDataOutside();
  };

  removeLocationItem(id: string) {
    this.locations = filter(this.locations, (item: any) => item.locationId !== id);
    //
    this.sentDataOutside();
  }

  sentDataOutside() {
    if (this.onLocationsChange) {
      // sent list Location Ids array
      this.onLocationsChange.emit(this.locations.map(item => item.locationId));
    }
  }

  loadDataFromQueryParam() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (!isEmpty(queryParams['classRoom.parent.id'])) {
      this.subject.next();
    }
  }

  reloadDataFromQueryParam() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (!isEmpty(queryParams['classRoom.parent.id'])) {
      this.subject.next('reload');
    }
  }
}

