import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { reduce } from 'lodash';
import { getNotificationItems, getNotificationNumberValue } from '../../../../utils';
import { NotificationSettings, NotificationSetting } from '../../model/settings.model';
import { takeUntil, map , debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { dateFormat, convertedDate } from '../../../../utils';
import { NotificationType } from '../../model/settings.model';
import { Store } from '@ngrx/store';
import { State } from '../../../store/index';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import { SettingsRepository } from '../../shared/settings.repository';
import { SnackbarStatus } from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-notification-form',
  templateUrl: './setting-notification-form.component.html',
  styleUrls: ['./setting-notification-form.component.scss']
})
export class SettingNotificationFormComponent implements OnInit, OnDestroy {
  @Input() pending: boolean;
  @Input() formData?: NotificationSetting[];
  submit$ = new Subject<NotificationSettings>();
  form: FormGroup;
  notificationOrigin: any;
  isChangeNotification = false;
  private unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private settingsRepository: SettingsRepository,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.form = this.buildForm();
    // convert data to object of form to compare show or hide button save
    this.notificationOrigin = reduce(this.formData , function(obj, param) {
      const notificationType = param.notificationType;
      if (
        notificationType === NotificationType.NUMBER_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL ||
        notificationType === NotificationType.NUMBER_LAST_LESSON_INVOICE_TO_ADMIN_MAIL
      ) {
        obj[notificationType] = param.numberValue;
      } else {
        obj[notificationType] = param.active;
      }

      return obj;
     }, {});
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((data) => {
        if (this.compareObject(data, this.notificationOrigin)) {
          this.isChangeNotification = false;
        } else {
          this.isChangeNotification = true;
        }
      });

    this.form.controls['REMIND_NUMDAY_BEFORE_LESSON_START'].valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('REMIND_NUMDAY_BEFORE_FIRST_LESSON_START').setValue(false);
          this.form.get('REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL').setValue(false);
        }
      });

    this.form.controls['REMIND_NUMDAY_BEFORE_LESSON_START_MAIL'].valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('REMIND_NUMDAY_BEFORE_FIRST_LESSON_START').setValue(false);
          this.form.get('REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL').setValue(false);
        }
      });
    this.form.controls['REMIND_NUMDAY_BEFORE_FIRST_LESSON_START'].valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('REMIND_NUMDAY_BEFORE_LESSON_START').setValue(false);
          this.form.get('REMIND_NUMDAY_BEFORE_LESSON_START_MAIL').setValue(false);
        }
      });
    this.form.controls['REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL'].valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (value) {
          this.form.get('REMIND_NUMDAY_BEFORE_LESSON_START').setValue(false);
          this.form.get('REMIND_NUMDAY_BEFORE_LESSON_START_MAIL').setValue(false);
        }
      });

    this.getSubmitData()
    .subscribe((submitData) => {
      this.notificationOrigin = {...submitData};
      this.isChangeNotification = false;
      const parseData = this.parseDataNotification(submitData);
      this.submitData(parseData);
    });
  }
  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      map(() => this.form.value),
    );
  }

  submitData(data) {
    this.spinnerService.start();
      // update notification
      this.settingsRepository.putNotificationSetting(data).subscribe(res => {
          this.formData = res.notificationTypeSettings;
           this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.saveSuccess'));
           this.spinnerService.stop();
      }, (err) => {
          this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
          this.spinnerService.stop();
      });
  }

  private buildForm() {
    return this.formBuilder
      .group({
        ADMIN_CHANGE_USER_EMAIL : [
          getNotificationItems(this.formData, NotificationType.ADMIN_CHANGE_USER_EMAIL)
        ],
        DELETE_LESSON: [
          getNotificationItems(this.formData, NotificationType.DELETE_LESSON)
        ],
        DELETE_LESSON_MAIL: [
          getNotificationItems(this.formData, NotificationType.DELETE_LESSON_MAIL)
        ],
        // BOOKING_LESSON_ONLINE: [
        //   getNotificationItems(this.formData, NotificationType.BOOKING_LESSON_ONLINE)
        // ],
        CREATE_NEW_LESSON: [
          getNotificationItems(this.formData, NotificationType.CREATE_NEW_LESSON)
        ],

        REGIST_STUDENT_INTO_LESSON: [
          getNotificationItems(this.formData, NotificationType.REGIST_STUDENT_INTO_LESSON)
        ],
        REGIST_STUDENT_INTO_LESSON_MAIL: [
          getNotificationItems(this.formData, NotificationType.REGIST_STUDENT_INTO_LESSON_MAIL)
        ],
        REMOVE_STUDENT_OUT_LESSON: [
          getNotificationItems(this.formData, NotificationType.REMOVE_STUDENT_OUT_LESSON)
        ],
        REMOVE_STUDENT_OUT_LESSON_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMOVE_STUDENT_OUT_LESSON_MAIL)
        ],
        TRANSFER_STUDENT: [
          getNotificationItems(this.formData, NotificationType.TRANSFER_STUDENT)
        ],
        TRANSFER_STUDENT_MAIL: [
          getNotificationItems(this.formData, NotificationType.TRANSFER_STUDENT_MAIL)
        ],
        UPDATE_LESSON: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON)
        ],
        UPDATE_LESSON_MAIL: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_MAIL)
        ],
        CHANGE_LESSON_TIME: [
          getNotificationItems(this.formData, NotificationType.CHANGE_LESSON_TIME)
        ],
        CHANGE_LESSON_TIME_MAIL: [
          getNotificationItems(this.formData, NotificationType.CHANGE_LESSON_TIME_MAIL)
        ],
        REMIND_NUMDAY_BEFORE_LESSON_START: [
          getNotificationItems(this.formData, NotificationType.REMIND_NUMDAY_BEFORE_LESSON_START)
        ],
        REMIND_NUMDAY_BEFORE_FIRST_LESSON_START: [
          getNotificationItems(this.formData, NotificationType.REMIND_NUMDAY_BEFORE_FIRST_LESSON_START)
        ],
        REMIND_NUMDAY_BEFORE_LESSON_START_MAIL: [
           getNotificationItems(this.formData, NotificationType.REMIND_NUMDAY_BEFORE_LESSON_START_MAIL)
        ],
        REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMIND_NUMDAY_BEFORE_FIRST_LESSON_START_MAIL)
       ],
        MARK_ATTENDANCE: [
          getNotificationItems(this.formData, NotificationType.MARK_ATTENDANCE)
        ],
        MARK_ATTENDANCE_MAIL: [
          getNotificationItems(this.formData, NotificationType.MARK_ATTENDANCE_MAIL)
        ],
        UPDATE_LESSON_ONLINE_NOTE: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_ONLINE_NOTE)
        ],
        UPDATE_LESSON_ONLINE_NOTE_MAIL: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_ONLINE_NOTE_MAIL)
        ],
        MAKEUP_ATTACHED_STUDENT: [
          getNotificationItems(this.formData, NotificationType.MAKEUP_ATTACHED_STUDENT)
        ],
        MAKEUP_ATTACHED_STUDENT_MAIL: [
          getNotificationItems(this.formData, NotificationType.MAKEUP_ATTACHED_STUDENT_MAIL)
        ],
        CREATE_NEW_INVOICE_OFFLINE: [
          getNotificationItems(this.formData, NotificationType.CREATE_NEW_INVOICE_OFFLINE)
        ],
        CREATE_NEW_INVOICE_OFFLINE_MAIL: [
          getNotificationItems(this.formData, NotificationType.CREATE_NEW_INVOICE_OFFLINE_MAIL)
        ],
        // REGENERATE_INVOICE: [
        //   getNotificationItems(this.formData, NotificationType.REGENERATE_INVOICE)
        // ],
        ADMIN_CHANGE_PAYMENT_STATUS: [
          getNotificationItems(this.formData, NotificationType.ADMIN_CHANGE_PAYMENT_STATUS)
        ],
        ADMIN_CHANGE_PAYMENT_STATUS_MAIL: [
          getNotificationItems(this.formData, NotificationType.ADMIN_CHANGE_PAYMENT_STATUS_MAIL)
        ],
        REMIND_INVOICE_BECOME_OVERDUE: [
          getNotificationItems(this.formData, NotificationType.REMIND_INVOICE_BECOME_OVERDUE)
        ],
        REMIND_INVOICE_BECOME_OVERDUE_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMIND_INVOICE_BECOME_OVERDUE_MAIL)
        ],
        TEACHER_NOTE: [
          getNotificationItems(this.formData, NotificationType.TEACHER_NOTE)
        ],
        TEACHER_NOTE_MAIL: [
          getNotificationItems(this.formData, NotificationType.TEACHER_NOTE_MAIL)
        ],
        NEW_ONLINE_REG_TO_TEACHER_STAFF_MAIL: [
          getNotificationItems(this.formData, NotificationType.NEW_ONLINE_REG_TO_TEACHER_STAFF_MAIL)
        ],
        REQ_LESSON_ONLINE_TO_TEACHER_STAFF_MAIL: [
          getNotificationItems(this.formData, NotificationType.REQ_LESSON_ONLINE_TO_TEACHER_STAFF_MAIL)
        ],
        NEW_ONLINE_PAYMENT_MADE_MAIL: [
          getNotificationItems(this.formData, NotificationType.NEW_ONLINE_PAYMENT_MADE_MAIL)
        ],
        REMIND_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMIND_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL)
        ],
        NUMBER_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL: [
          getNotificationNumberValue(this.formData, NotificationType.NUMBER_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL)
        ],
        REMIND_LAST_LESSON_TO_TEACHER_STAFF_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMIND_LAST_LESSON_TO_TEACHER_STAFF_MAIL)
        ],
        REMIND_LAST_LESSON_INVOICE_TO_ADMIN_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMIND_LAST_LESSON_INVOICE_TO_ADMIN_MAIL)
        ],
        NUMBER_LAST_LESSON_INVOICE_TO_ADMIN_MAIL: [
          getNotificationNumberValue(this.formData, NotificationType.NUMBER_LAST_LESSON_INVOICE_TO_ADMIN_MAIL)
        ],
        REMIND_LAST_LESSON_TO_ADMIN_MAIL: [
          getNotificationItems(this.formData, NotificationType.REMIND_LAST_LESSON_TO_ADMIN_MAIL)
        ],
        ASSIGNED_LESSON_TO_TEACHER: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_LESSON_TO_TEACHER)
        ],
        ASSIGNED_LESSON_TO_TEACHER_MAIL: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_LESSON_TO_TEACHER_MAIL)
        ],
        ASSIGNED_LESSON_TO_TEACHER_SECONDARY: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_LESSON_TO_TEACHER_SECONDARY)
        ],
        ASSIGNED_LESSON_TO_TEACHER_SECONDARY_MAIL: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_LESSON_TO_TEACHER_SECONDARY_MAIL)
        ],
        ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER)
        ],
        ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_MAIL: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_MAIL)
        ],
        ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY)
        ],
        ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY_MAIL: [
          getNotificationItems(this.formData, NotificationType.ASSIGNED_STUDENT_TO_MAKEUPLESSON_TEACHER_SECONDARY_MAIL)
        ],
        UPDATE_LESSON_TEACHER: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_TEACHER)
        ],
        UPDATE_LESSON_MAIL_TEACHER: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_MAIL_TEACHER)
        ],
        UPDATE_LESSON_SECONDARY_TEACHER: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_SECONDARY_TEACHER)
        ],
        UPDATE_LESSON_MAIL_SECONDARY_TEACHER: [
          getNotificationItems(this.formData, NotificationType.UPDATE_LESSON_MAIL_SECONDARY_TEACHER)
        ],
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getDate() {
    return convertedDate(new Date(), dateFormat.date);
  }

  parseDataNotification(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      let item = null;
      if (
        key === NotificationType.NUMBER_LAST_LESSON_INVOICE_TO_TEACHER_STAFF_MAIL ||
        key === NotificationType.NUMBER_LAST_LESSON_INVOICE_TO_ADMIN_MAIL
      ) {
        item = {
          valueType: NotificationType.VALUE_TYPE_NUMBER_VALUE,
          numberValue: data[key] > 0 ? data[key] : 1
        };
      } else {
        item = { valueType: NotificationType.VALUE_TYPE_ACTIVE, active: data[key] };
      }
      result.push({ ...item, notificationType: key });
    });
    result.forEach(element => {
      this.formData.forEach(notification => {
        if (element.notificationType === notification.notificationType && notification.id) {
          element.id = notification.id;
        }
      });
    });
    return result;
  }
  compareObject(object: any, originObject: any): boolean {
    for (const key in object) {
      // check also if property is not inherited from prototype
      if (object.hasOwnProperty(key)) {
        if (originObject[key] !== object[key]) {
          return false;
        }
      }
    }
    return true;
  }
  validateLastLessonReminderNumber(event) {
    const target = event.target;
    const modifiedValue = target.value.replaceAll('-', '').substring(0, 2);

    target.value = modifiedValue > 0 ? modifiedValue : '';
  }
}
