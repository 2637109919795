import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'e-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent {
  showDialog = false;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Input() isFullScreen = false;
  @Input() disableOutsideClick = false;
  @Input() customDialogItemClasses = '';
  @Input() customDialogContentClasses = '';

  @Input()
  get isActive() {
    return this.showDialog;
  }

  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  toggleDialog() {
    return this.isActive = !this.isActive;
  }

}
