import { Action } from '@ngrx/store';
import {AuthData, LoginCredentials, LogonLocationData, PasswordData, RequestForResetData} from '../../model';
import { Location } from '../../../../+settings/model/settings.model';
export const AUTH_ACTIVATE = '[Auth] AUTH_ACTIVATE';
export const AUTH_REQUEST_FOR_RESET = '[Auth] AUTH_REQUEST_FOR_RESET';
export const AUTH_LOGIN = '[Auth] AUTH_LOGIN';
export const AUTH_LOGOUT = '[Auth] AUTH_LOGOUT';
export const AUTH_REFRESH_TOKEN = '[Auth] AUTH_REFRESH_TOKEN';
export const AUTH_GET_ME = '[Auth] AUTH_GET_ME';
export const VERIFY_TOKEN = '[Auth] VERIFY_TOKEN';
export const UPDATE_PRIMARY_LOCATION_ON_PROFILE = '[Auth] UPDATE_PRIMARY_LOCATION_ON_PROFILE';
export const UPDATE_LEARNING_CENTER_SETTING_ON_PROFILE = '[Auth] UPDATE_LEARNING_CENTER_SETTING_ON_PROFILE';
export const SYNC_LOG_LOGOUT = '[Auth] SYNC_LOG_LOGOUT';
export const REGISTER_LOGON_LOCATION = '[Auth] REGISTER_LOGON_LOCATION';
export const REVOKE_LOGON_LOCATION = '[Auth] REVOKE_LOGON_LOCATION';
export const REFRESH_LOGON_LOCATION = '[Auth] REFRESH_LOGON_LOCATION';
export const SYNC_LOG = '[Auth] SYNC_LOG';
export const CHANGE_LOGON_LOCATION = '[Auth] CHANGE_LOGON_LOCATION';

export class AuthActivation implements Action {
  readonly type = AUTH_ACTIVATE;

  constructor(public passwordData: PasswordData) { }
}

export class AuthRequestForReset implements Action {
  readonly type = AUTH_REQUEST_FOR_RESET;

  constructor(public requestForResetData: RequestForResetData) { }
}

export class AuthLogin implements Action {
  readonly type = AUTH_LOGIN;

  constructor(public credentials: LoginCredentials) { }
}

export class AuthLogout implements Action {
  readonly type = AUTH_LOGOUT;
}

export class AuthRefreshToken implements Action {
  readonly type = AUTH_REFRESH_TOKEN;

  constructor(public response: AuthData) { }
}

export class AuthGetMe implements Action {
  readonly type = AUTH_GET_ME;
  constructor(public navigateHome: boolean = false) { }
}

export class VerifyToken implements Action {
  readonly type = VERIFY_TOKEN;

  constructor(public token: string) { }
}

export class UpdatePrimaryLocationOnProfile implements Action {
  readonly type = UPDATE_PRIMARY_LOCATION_ON_PROFILE;

  constructor(public location: Location) { }
}

export class UpdateLearningCenterSettingOnProfile implements Action {
  readonly type = UPDATE_LEARNING_CENTER_SETTING_ON_PROFILE;

  constructor(public data: any) { }
}

export class SyncLogLogout implements Action {
  readonly type = SYNC_LOG_LOGOUT;

  constructor() { }
}

export class RefreshLogonLocation implements Action {
  readonly type = REFRESH_LOGON_LOCATION;

  constructor(
  ) { }
}

export class RegisterLogonLocation implements Action {
  readonly type = REGISTER_LOGON_LOCATION;

  constructor(
    public logonLocation: LogonLocationData,
    public token?: string,
    public redirectUrl?: string
  ) { }
}

export class RevokeLogonLocation implements Action {
  readonly type = REVOKE_LOGON_LOCATION;

  constructor() { }
}

export class SyncLog implements Action {
  readonly type = SYNC_LOG;

  constructor(public data: any) { }
}

export class ChangeLogonLocation implements Action {
  readonly type = CHANGE_LOGON_LOCATION;

  constructor(
    public logonLocation: LogonLocationData,
  ) { }
}

export type All =
  | AuthActivation
  | AuthRequestForReset
  | AuthLogin
  | AuthLogout
  | AuthRefreshToken
  | AuthGetMe
  | VerifyToken
  | UpdatePrimaryLocationOnProfile
  | UpdateLearningCenterSettingOnProfile
  | SyncLogLogout
  | RegisterLogonLocation
  | RevokeLogonLocation
  | SyncLog
  | ChangeLogonLocation
;
