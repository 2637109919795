import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {concat, of} from 'rxjs';
import {catchError, filter, map, mergeMap, take} from 'rxjs/operators';
import * as QueryActions from '../../../store/query/query.actions';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import * as LessonActions from './lesson.actions';
import * as CalendarActions from '../../../core/calendar/store/calendar.actions';
import {getDateAfter, getDateBefore} from '../../shared/lesson.utils';
import {
  COMPLETE_LESSON,
  DELETE_LESSON,
  DELETE_LESSONS,
  DELETE_TEACHER_NOTE,
  EDIT_ATTENDANCE_LESSON,
  EDIT_DISCOUNT_SERIES,
  EDIT_ONLINE_LESSON,
  EDIT_ONLINE_LESSON_SERIAL,
  EDIT_SETTING_LESSON_SERIAL,
  GET_BOOKING,
  GET_BOOKINGS_QUERY,
  GET_LAST_LESSONS,
  GET_LESSON,
  GET_LESSONS,
  GET_TEACHER_NOTE,
  POST_ASSIGN_TO_LESSON,
  POST_CATEGORY,
  POST_CLASSROOM,
  POST_LESSON,
  POST_LOCATION,
  POST_TEACHER_NOTE,
  PUT_ATTACHED_STUDENT_LESSON,
  PUT_BOOKING_LESSONS,
  PUT_LESSON,
  PUT_LESSON_TEACHER,
  PUT_LESSONS,
  PUT_TEACHER_NOTE,
  TRANSFER_STUDENTS,
  UNDO_STUDENT_ASSIGNED
} from './lesson.state';
import {
  GET_ABSENCE,
  GET_ABSENCE_SUCCESS,
  SYNC_LESSON_SMART_JEN,
  UPDATE_ABSENCE,
  UPDATE_ABSENCE_SUCCESS
} from './lesson.actions';
import {SnackbarStatus} from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import {routerActions, RouterState} from '../../../store/router';
import {LessonRepository} from '../../shared/lesson.repository';
import {LearningCenterRepository} from '../../../core/learning-center/shared/learning-center.repository';
import {checkRouter} from '../../../../utils';
import {get} from 'lodash';
import {AuthRepository} from '../../../core/auth/shared/auth.repository';
import {SmartJenRepository} from '../../../+smartjen/shared/smartjen.repository';
import {LayoutService} from '../../../core/layout/shared/layout.service';
import {UIEnum} from '../../../../constants/UI.enum';
import {hasRoles} from '../../../core/user/shared/staff-member.utils';
import {PERMISSIONS_SET_STAFF_NAME, StaffMemberRoleEnum} from '../../../../constants/staff-member-role.enum';
import {getValue} from '../../../../utils/form.utils';
import {SpinnerService} from '../../../services/spinner.service';

@Injectable()
export class LessonEffects {

  constructor(
    private actions$: Actions,
    private lessonRepository: LessonRepository,
    private learningCenterRepository: LearningCenterRepository,
    private authRepository: AuthRepository,
    private smartJenRepository: SmartJenRepository,
    private layoutService: LayoutService,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() postLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.CreateLesson>(LessonActions.CREATE_LESSON),
      mergeMap(({ lesson, redirect, params }) => {
        const url = redirect ? decodeURIComponent(redirect) : '/lesson';
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(POST_LESSON)),
          this.lessonRepository
            .postLesson(lesson)
            .pipe(
              mergeMap(data => {
                if (get(data, 'id') && get(lesson, 'smartjenSynced')) {
                  return concat(
                    of(this.spinnerService.stop()),
                    of(new LessonActions.SyncLessonSmartJen(get(data, 'id'), true)),
                    of(new QueryActions.QuerySuccess(POST_LESSON, data)),
                    of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.createSuccess')),
                    of(new routerActions.Navigate({url, queryParams: params})),
                  );
                }
                return concat(
                  of(this.spinnerService.stop()),
                  of(new QueryActions.QuerySuccess(POST_LESSON, data)),
                  of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.createSuccess')),
                  of(new routerActions.Navigate({url, queryParams: params})),
                );
              }),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(POST_LESSON, error))),
              )),
        );
      }));

  @Effect() putLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.EditLesson>(LessonActions.EDIT_LESSON),
      mergeMap(({ id, lesson, redirect, params }) => {
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(PUT_LESSON)),
          this.lessonRepository
            .putLesson(id, lesson, params)
            .pipe(
              mergeMap(data => {
                if ( get(lesson, 'smartjenSynced') !== get(data, 'smartjenSynced') || get(lesson, 'smartJenChanged')
                ) {
                  return concat(
                    of(this.spinnerService.stop()),
                    of(new LessonActions.SyncLessonSmartJen(get(data, 'id'), get(lesson, 'smartjenSynced'))),
                    of(new QueryActions.QuerySuccess(PUT_LESSON, data)),
                    of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                    of(new LessonActions.GetTeacherNote(id)),
                  );
                }

                return concat(
                  of(this.spinnerService.stop()),
                  of(new QueryActions.QuerySuccess(PUT_LESSON, data)),
                  of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                  of(new LessonActions.GetTeacherNote(id)),
                  );
                }
              ),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(PUT_LESSON, error)),
              )),
            ),
        );
      }),
    );
  @Effect() putAttedanceLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.EditLesson>(LessonActions.EDIT_ATTENDANCE_LESSON),
      mergeMap(({ id, lesson }) => {
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(EDIT_ATTENDANCE_LESSON)),
          this.lessonRepository.putAttendanceLesson(id, lesson)
            .pipe(
              mergeMap(data => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QuerySuccess(EDIT_ATTENDANCE_LESSON, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                of(new LessonActions.GetTeacherNote(id)),
              )),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(PUT_LESSON, error)),
                of(new LessonActions.SetLesson(id)),
              )),
            ),
        );
      }),
    );

  @Effect() putLessons$ = this.actions$
    .pipe(
      ofType<LessonActions.EditLessons>(LessonActions.EDIT_LESSONS),
      mergeMap(({ id, lesson, redirect , params}) => {
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(PUT_LESSONS)),
          this.lessonRepository
            .putLessons(id, lesson, params)
            .pipe(
              mergeMap(data => {
                if (get(lesson, 'smartjenSynced') !== get(data, 'smartjenSynced') || get(lesson, 'smartJenChanged')) {
                  return concat(
                    of(this.spinnerService.stop()),
                    of(new LessonActions.SyncLessonSmartJen(get(data, 'id'), get(lesson, 'smartjenSynced'))),
                    of(new QueryActions.QuerySuccess(PUT_LESSONS, data)),
                    of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess'))
                  );
                }
                return concat(
                  of(this.spinnerService.stop()),
                  of(new QueryActions.QuerySuccess(PUT_LESSONS, data)),
                  of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                );
              }),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(PUT_LESSONS, error)),
              )),
            ),
          );
      }),
    );

  @Effect() putLessonTeacher$ = this.actions$
    .pipe(
      ofType<LessonActions.EditLessonTeacher>(LessonActions.EDIT_LESSON_TEACHER),
      mergeMap(({ id, lesson, redirect, params }) => {
        // const url = redirect ? decodeURIComponent(redirect) : '/lesson';
        return concat(
        of(new QueryActions.QueryInProgress(PUT_LESSON_TEACHER)),
        this.lessonRepository
          .putLessonTeacher(id, lesson, params)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(PUT_LESSON_TEACHER, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
              // of(new routerActions.Navigate({ url })),
            )),
            catchError(error => of(new QueryActions.QueryFailure(PUT_LESSON_TEACHER, error))),
          ),
        );
      }),
    );

  @Effect() putAttachedStudentsLessons$ = this.actions$
    .pipe(
      ofType<LessonActions.EditAttachedStudentsLesson>(LessonActions.EDIT_ATTACHED_STUDENTS_LESSON),
      mergeMap(({ id, lesson, redirect, params }) => {
        return concat(
        of(new QueryActions.QueryInProgress(PUT_ATTACHED_STUDENT_LESSON)),
        of(this.spinnerService.start()),
        this.lessonRepository
          .putAttachedStudentsLesson(id, lesson, params)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(PUT_ATTACHED_STUDENT_LESSON, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
              of(this.spinnerService.stop()),
              of(new LessonActions.GetTeacherNote(id)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(PUT_ATTACHED_STUDENT_LESSON, error)),
              of(this.spinnerService.stop()),
              )),
          ),
        );
      }),
    );

  @Effect() getLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.SetLesson>(LessonActions.SET_LESSON),
      mergeMap(({ id }) => concat(
        of(new QueryActions.QueryInProgress(GET_LESSON)),
        of(this.spinnerService.start()),
        this.lessonRepository.getLesson(id)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_LESSON, data)),
              of(new QueryActions.QuerySuccess(PUT_LESSON, data)),
              of(new LessonActions.SetLessonSuccess(data)),
              of(new CalendarActions.ShouldRefresh(false)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_LESSON, error)),
            )),
          ),
        ),
      ),
    );

  @Effect() getLessons$ = this.actions$
    .pipe(
      ofType<LessonActions.GetLessons>(LessonActions.GET_LESSONS),
      mergeMap(({ queryParams , saveLastItemPerPage}) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_LESSONS)),
        this.lessonRepository
          .getLessons(queryParams, saveLastItemPerPage)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_LESSONS, data)),
              of(new LessonActions.GetLessonsSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_LESSONS, error)),
            )),
          ),
      )),
    );

  @Effect() fetchIncompletedLessons$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'lesson/incompleted')),
      mergeMap(({url, queryParams}) =>
        this.layoutService
          .getMe()
          .pipe(
            filter(linkedStaffMember => !!linkedStaffMember),
            take(1),
            mergeMap(linkedStaffMember => of(new LessonActions.GetLessons(
              (hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER)
                && getValue(linkedStaffMember, 'permissionsSet.name') !== PERMISSIONS_SET_STAFF_NAME)
                ? {
                  ...queryParams,
                  'start[after]': getDateAfter(queryParams['start[after]']),
                  'byTeacher': linkedStaffMember.id
                }
                : {
                  ...queryParams,
                  'start[after]': getDateAfter(queryParams['start[after]']),
                },
              UIEnum.SAVE_LAST_ITEM_PER_PAGE_LESSON
            )))
          ),
      ),
    );

  @Effect() fetchCompletedLessons$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'lesson/completed')),
      mergeMap(({ url, queryParams }) =>
        this.layoutService
          .getMe()
          .pipe(
            filter(linkedStaffMember => !!linkedStaffMember),
            take(1),
            mergeMap(linkedStaffMember => of(new LessonActions.GetLessons(
              (hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER)
                && getValue(linkedStaffMember, 'permissionsSet.name') !== PERMISSIONS_SET_STAFF_NAME)
                ? {
                  ...queryParams,
                  'start[before]': getDateBefore(queryParams['start[before]']),
                  'byTeacher': linkedStaffMember.id
                }
                : {
                  ...queryParams,
                  'start[before]': getDateBefore(queryParams['start[before]']),
                }
              ,
              UIEnum.SAVE_LAST_ITEM_PER_PAGE_LESSON
            )))
          ),
      ),
    );

  @Effect() clearLessons$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url, queryParams }) => checkRouter(url, 'lesson/recurring') && !queryParams.recurring),
      mergeMap(({ url, queryParams }) => concat(
        of(new LessonActions.ClearLessons())
      )),
    );

  @Effect() fetchRecurringLessons$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url, queryParams }) => checkRouter(url, 'lesson/recurring') && queryParams.recurring),
      mergeMap(({ url, queryParams }) => concat(
        of(new LessonActions.GetLessons(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_LESSON))
      )),
    );

  @Effect() deleteLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.DeleteLesson>(LessonActions.DELETE_LESSON),
      mergeMap(({ id, redirectUrl }) => concat(
        of(new QueryActions.QueryInProgress(DELETE_LESSON)),
        of(this.spinnerService.start()),
        this.lessonRepository
          .deleteLesson(id)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_LESSON, data)),
              of(this.spinnerService.stop()),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.deleteSuccess')),
              !!redirectUrl ? of(new routerActions.Navigate({ url: redirectUrl })) : of(),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(DELETE_LESSON, error)),
              of(this.spinnerService.stop()),
            )),
          ),
      )),
    );

  @Effect() deleteLessons$ = this.actions$
    .pipe(
      ofType<LessonActions.DeleteLessons>(LessonActions.DELETE_LESSONS),
      mergeMap(({ queryParams }) => concat(
        of(new QueryActions.QueryInProgress(DELETE_LESSONS)),
        of(this.spinnerService.start()),
        this.lessonRepository
          .deleteLessons(queryParams)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_LESSONS, data)),
              of(this.spinnerService.stop()),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.deleteSuccess')),
              of(new routerActions.Navigate({ url: '/lesson' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(DELETE_LESSONS, error)),
            )),
          ),
      )),
    );

  @Effect() completeLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.CompleteLesson>(LessonActions.COMPLETE_LESSON),
      mergeMap(({ id, redirectUrl, queryParams }) => concat(
        of(new QueryActions.QueryInProgress(COMPLETE_LESSON)),
        of(this.spinnerService.start()),
        this.lessonRepository
          .completeLesson(id)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(COMPLETE_LESSON, data)),
              of(this.spinnerService.stop()),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.completeSuccess')),
              !!redirectUrl
                ? of(new routerActions.Navigate({ url: redirectUrl, queryParams }))
                : of(),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(COMPLETE_LESSON, error)),
              of(this.spinnerService.stop()),
            )),
          ),
      )),
    );

  @Effect() postAssignToLesson$ = this.actions$
    .pipe(
      ofType<LessonActions.AssignToLesson>(LessonActions.ASSIGN_TO_LESSON),
      mergeMap(({ makeup, redirect }) => {
        const url = redirect ? decodeURIComponent(redirect) : '/lesson/absences';
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(POST_ASSIGN_TO_LESSON)),
          this.lessonRepository
            .postAssignToLesson(makeup)
            .pipe(
              mergeMap(data => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QuerySuccess(POST_ASSIGN_TO_LESSON, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.assignSuccess')),
                of(new routerActions.Navigate({ url })),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_ASSIGN_TO_LESSON, error)),
                of(this.spinnerService.stop()),
              )),
            ),
        );
      }),
    );

  @Effect() postMakeup$ = this.actions$
    .pipe(
      ofType<LessonActions.CreateMakeup>(LessonActions.CREATE_MAKEUP),
      mergeMap(({ lesson, attachedStudent, redirect }) => {
        const url = redirect ? decodeURIComponent(redirect) : '/lesson';
        return concat(
          of(new QueryActions.QueryInProgress(POST_LESSON)),
          this.lessonRepository
            .postMakeupLesson(lesson, attachedStudent)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(POST_LESSON, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.createSuccess')),
                of(new routerActions.Navigate({ url })),
              )),
              catchError(error => of(new QueryActions.QueryFailure(POST_LESSON, error))),
            ),
        );
      }),
    );

  @Effect() updateAbsence$ = this.actions$
    .pipe(
      ofType<LessonActions.UpdateAbsence>(LessonActions.UPDATE_ABSENCE),
      mergeMap(({ payload }) => concat(
        of(new QueryActions.QueryInProgress(UPDATE_ABSENCE)),
        this.lessonRepository
          .updateAbsence(payload)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(UPDATE_ABSENCE_SUCCESS, data)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.student.absence.updateSuccess')),
              of(new LessonActions.UpdateAbsenceSuccess(payload)),
            )),
            catchError(error => of(new QueryActions.QueryFailure(UPDATE_ABSENCE, error))),
          ),
      )),
    );

  @Effect() fetchOriginalLesson$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url, queryParams }) => checkRouter(url, 'create-makeup') || (checkRouter(url, 'calendar') && queryParams.attachedStudent)),
      mergeMap(({ queryParams }) =>
        this.lessonRepository
          .getLessons({ attachedStudents: queryParams.attachedStudent })
          .pipe(
            filter(data => !!data),
            take(1),
            mergeMap(data => of(new LessonActions.SetOriginalLesson(data.data[0])))
          ),
      )
    );

  @Effect() fetchAbsence$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'lesson/absences')),
      mergeMap(({ url, queryParams }) =>
        this.authRepository
          .getMe()
          .pipe(
            filter(linkedStaffMember => !!linkedStaffMember),
            take(1),
            mergeMap(linkedStaffMember => of(new LessonActions.GetAbsence(
              (hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER)
                && getValue(linkedStaffMember, 'permissionsSet.name') !== PERMISSIONS_SET_STAFF_NAME) ? {
                ...queryParams,
                'byTeacher': linkedStaffMember.id
              } : { ...queryParams }
            )
            ))
          ),
      )
    );

    @Effect() getAbsence$ = this.actions$
    .pipe(
      ofType<LessonActions.GetAbsence>(LessonActions.GET_ABSENCE),
      mergeMap(({ queryParams }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_ABSENCE)),
        this.lessonRepository
          .getAbsence(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_LESSON)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_ABSENCE_SUCCESS, data)),
              of(new LessonActions.GetAbsenceSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_ABSENCE, error)),
            )),
          ),
      )),
    );

  @Effect() undoStudentAssigned$ = this.actions$
    .pipe(
      ofType<LessonActions.UndoStudentAssigned>(LessonActions.UNDO_STUDENT_ASSIGNED),
      mergeMap(({ studentId }) => concat(
        of(new QueryActions.QueryInProgress(UNDO_STUDENT_ASSIGNED)),
        of(this.spinnerService.start()),
        this.lessonRepository
          .undoStudentAssigned(studentId)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(UNDO_STUDENT_ASSIGNED, data)),
              of(this.spinnerService.stop()),
              of(new routerActions.ReloadPage()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(UNDO_STUDENT_ASSIGNED, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        ),
      ),
    );

  @Effect() updateBookingLessons$ = this.actions$
    .pipe(
      ofType<LessonActions.UpdateBookingLessons>(LessonActions.UPDATE_BOOKING_LESSONS),
      mergeMap(({ data }) => {
        return concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(PUT_BOOKING_LESSONS)),
          this.lessonRepository
            .postBookingLessons(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_BOOKING_LESSONS, resp)),
                of(this.spinnerService.stop()),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_BOOKING_LESSONS, error)),
                of(this.spinnerService.stop()),
              ))
            )
        );
      }));

    @Effect() fetchBookings$ = this.actions$
      .pipe(
        ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
        map(({ payload: { routerState } }) => routerState),
        filter(({ url }) => checkRouter(url, 'lesson/booking')),
        mergeMap(({ url, queryParams }) =>
          this.authRepository
            .getMe()
            .pipe(
              filter(linkedStaffMember => !!linkedStaffMember),
              take(1),
              mergeMap(linkedStaffMember => of(new LessonActions.GetBookings(
                (hasRoles(linkedStaffMember, StaffMemberRoleEnum.TEACHER)
                  && getValue(linkedStaffMember, 'permissionsSet.name') !== PERMISSIONS_SET_STAFF_NAME) ? {
                  ...queryParams,
                  'byTeacher': linkedStaffMember.id
                } : {...queryParams}
              )))
            )),
      );

    @Effect() getBookings$ = this.actions$
      .pipe(
        ofType<LessonActions.GetBookings>(LessonActions.GET_BOOKINGS),
        mergeMap(({ queryParams }) => concat(
          of(this.spinnerService.start()),
          of(new QueryActions.QueryInProgress(GET_BOOKINGS_QUERY)),
          this.lessonRepository
            .getBookings(queryParams, UIEnum.SAVE_LAST_ITEM_PER_PAGE_LESSON)
            .pipe(
              mergeMap(data => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QuerySuccess(GET_BOOKINGS_QUERY, data)),
                of(new LessonActions.GetBookingsSuccess(data)),
              )),
              catchError(error => concat(
                of(this.spinnerService.stop()),
                of(new QueryActions.QueryFailure(GET_BOOKINGS_QUERY, error)),
              )),
            ),
        )),
      );

      @Effect() getBooking$ = this.actions$
      .pipe(
        ofType<LessonActions.GetBooking>(LessonActions.GET_BOOKING),
        mergeMap(({ id }) => concat(
          of(new QueryActions.QueryInProgress(GET_BOOKING)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .getBooking(id)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(GET_BOOKING, data)),
                of(this.spinnerService.stop()),
                of(new LessonActions.SetBooking(data)),
              )),
              catchError(error => of(new QueryActions.QueryFailure(GET_BOOKING, error))),
            ),
          ),
        ),
      );

      @Effect() postClassroom$ = this.actions$
      .pipe(
        ofType<LessonActions.CreateClassroom>(LessonActions.POST_CLASSROOM),
        mergeMap(({ data }) => {
          return concat(
          of(new QueryActions.QueryInProgress(POST_CLASSROOM)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .postClassroom(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(POST_CLASSROOM, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.createClassroomSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_CLASSROOM, error)),
                of(this.spinnerService.stop()),
              )),
            ),
          );
        }),
      );

      @Effect() postLocation$ = this.actions$
      .pipe(
        ofType<LessonActions.CreateLocation>(LessonActions.POST_LOCATION),
        mergeMap(({ data }) => {
          return concat(
          of(new QueryActions.QueryInProgress(POST_LOCATION)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .postClassroom(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(POST_LOCATION, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.createSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_LOCATION, error)),
                of(this.spinnerService.stop()),
              )),
            ),
          );
        }),
      );

    @Effect() postTeacherNote$ = this.actions$
    .pipe(
      ofType<LessonActions.CreateTeacherNote>(LessonActions.CREATE_TEACHER_NOTE),
      mergeMap(({ teacherNote, isSend }) => {
        return concat(
          of(new QueryActions.QueryInProgress(POST_TEACHER_NOTE)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .postTeacherNote(teacherNote)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(POST_TEACHER_NOTE, resp)),
                isSend ? of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'lesson.sentTeacherNoteSuccess')) :
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'lesson.drafTeacherNoteSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(POST_LOCATION, error)),
                of(this.spinnerService.stop()),
              )),
            ),
          );
        }),
      );

    @Effect() putTeacherNote$ = this.actions$
    .pipe(
      ofType<LessonActions.UpdateTeacherNote>(LessonActions.UPDATE_TEACHER_NOTE),
      mergeMap(({ teacherNote, isSend }) => {
        return concat(
          of(new QueryActions.QueryInProgress(PUT_TEACHER_NOTE)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .putTeacherNote(teacherNote)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(PUT_TEACHER_NOTE, resp)),
                isSend ? of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'lesson.sentTeacherNoteSuccess')) :
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'lesson.drafTeacherNoteSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(PUT_TEACHER_NOTE, error)),
                of(this.spinnerService.stop()),
              )),
            ),
          );
        }),
      );

    @Effect() deleteTeacherNote$ = this.actions$
    .pipe(
      ofType<LessonActions.DeleteTeacherNote>(LessonActions.DELETE_TEACHER_NOTE),
      mergeMap(({ id }) => {
        return concat(
          of(new QueryActions.QueryInProgress(DELETE_TEACHER_NOTE)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .deleteTeacherNote(id)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(DELETE_TEACHER_NOTE, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'lesson.deleteTeacherNoteSuccess')),
                of(this.spinnerService.stop()),
                of(new LessonActions.DeleteTeacherNoteSuccess(id)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(DELETE_TEACHER_NOTE, error)),
                of(this.spinnerService.stop()),
              )),
            ),
          );
        }),
      );

    @Effect() getListTeacherNote$ = this.actions$
    .pipe(
      ofType<LessonActions.GetTeacherNote>(LessonActions.GET_TEACHER_NOTE),
      mergeMap(({ lessonId }) => {
        return concat(
          of(new QueryActions.QueryInProgress(GET_TEACHER_NOTE)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .getTeacherNotes(lessonId)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(GET_TEACHER_NOTE, resp)),
                of(this.spinnerService.stop()),
                of(new LessonActions.GetTeacherNoteSuccess(resp)),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(GET_TEACHER_NOTE, error)),
                of(this.spinnerService.stop()),
              )),
            ),
          );
        }),
      );

      @Effect() putOnlineLessons$ = this.actions$
      .pipe(
        ofType<LessonActions.EditOnlineLessonSerial>(LessonActions.EDIT_ONLINE_LESSON_SERIAL),
        mergeMap(({ id, lesson, redirect }) => {
          return concat(
          of(new QueryActions.QueryInProgress(EDIT_ONLINE_LESSON_SERIAL)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .putOnlineLessons(id, lesson)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(EDIT_ONLINE_LESSON_SERIAL, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(EDIT_ONLINE_LESSON_SERIAL, error)),
                of(this.spinnerService.stop()),
                )),
            ),
          );
        }),
      );

      @Effect() putOnlineLesson$ = this.actions$
      .pipe(
        ofType<LessonActions.EditOnlineLesson>(LessonActions.EDIT_ONLINE_LESSON),
        mergeMap(({ id, lesson, redirect }) => {
          return concat(
          of(new QueryActions.QueryInProgress(EDIT_ONLINE_LESSON)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .putOnlineLesson(id, lesson)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(EDIT_ONLINE_LESSON, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(EDIT_ONLINE_LESSON, error)),
                of(this.spinnerService.stop()),
                )),
            ),
          );
        }),
      );
      @Effect() putSettingLesson$ = this.actions$
      .pipe(
        ofType<LessonActions.EditSettingLessonSerial>(LessonActions.EDIT_SETTING_LESSON_SERIAL),
        mergeMap(({ id, lesson, redirect }) => {
          return concat(
          of(new QueryActions.QueryInProgress(EDIT_SETTING_LESSON_SERIAL)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .putSettingLessons(id, lesson)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(EDIT_SETTING_LESSON_SERIAL, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(EDIT_SETTING_LESSON_SERIAL, error)),
                of(this.spinnerService.stop()),
                )),
            ),
          );
        }),
      );
      @Effect() putDiscountSeries$ = this.actions$
      .pipe(
        ofType<LessonActions.EditDiscountSeries>(LessonActions.EDIT_DISCOUNT_SERIES),
        mergeMap(({ id, series, redirect }) => {
          return concat(
          of(new QueryActions.QueryInProgress(EDIT_DISCOUNT_SERIES)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .putDiscountSeries(id, series)
            .pipe(
              mergeMap(data => concat(
                of(new QueryActions.QuerySuccess(EDIT_DISCOUNT_SERIES, data)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'form.lesson.saveSuccess')),
                of(this.spinnerService.stop()),
              )),
              catchError(error => {
                if (get(error, 'error.detail', null) === 'ERROR.PROPERTY.DUPLICATED_DISCOUNT_SERIES_OR_CONCURRENT') {
                  // with error duplicate discount reload after 2s
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
                return concat(
                  of(new QueryActions.QueryFailure(EDIT_SETTING_LESSON_SERIAL, error)),
                  of(this.spinnerService.stop()),
                  );
              }),
            ),
          );
        }),
      );

    @Effect() fetchLastLessons$ = this.actions$
    .pipe(
      ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
      map(({ payload: { routerState } }) => routerState),
      filter(({ url }) => checkRouter(url, 'lesson/last-lesson')),
      mergeMap(({ url, queryParams }) => concat(
        // of(new RouterActions.SetQuery({ 'start[after]': new Date() })),
        of(new LessonActions.GetLastLessons(queryParams))
      )),
    );

    @Effect() getLastLessons$ = this.actions$
    .pipe(
      ofType<LessonActions.GetLastLessons>(LessonActions.GET_LAST_LESSONS),
      mergeMap(({ queryParams }) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(GET_LAST_LESSONS)),
        this.lessonRepository
          .getLastLessons(queryParams)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(GET_LAST_LESSONS, data)),
              of(new LessonActions.GetLastLessonsSuccess(data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_LAST_LESSONS, error)),
            )),
          ),
      )),
    );

    @Effect() postCategory$ = this.actions$
    .pipe(
      ofType<LessonActions.CreateCategory>(LessonActions.POST_CATEGORY),
      mergeMap(({ data }) => {
        return concat(
        of(new QueryActions.QueryInProgress(POST_CATEGORY)),
        of(this.spinnerService.start()),
        this.learningCenterRepository
          .postCategory(data)
          .pipe(
            mergeMap(resp => concat(
              of(new QueryActions.QuerySuccess(POST_CATEGORY, resp)),
              of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.category.createSuccess')),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_CATEGORY, error)),
              of(this.spinnerService.stop()),
            )),
          ),
        );
      }),
    );

  @Effect() postTransferStudents$ = this.actions$
    .pipe(
      ofType<LessonActions.TransferStudents>(LessonActions.TRANSFER_STUDENTS),
      mergeMap(({ id, data }) => {
        return concat(
          of(new QueryActions.QueryInProgress(TRANSFER_STUDENTS)),
          of(this.spinnerService.start()),
          this.lessonRepository
            .postStudentsTransferRequest(data)
            .pipe(
              mergeMap(resp => concat(
                of(new QueryActions.QuerySuccess(TRANSFER_STUDENTS, resp)),
                of(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'lesson.postTransferStudentsSuccess')),
                of(this.spinnerService.stop()),
                of(new LessonActions.SetLesson(id))
              )),
              catchError(error => concat(
                of(new QueryActions.QueryFailure(TRANSFER_STUDENTS, error)),
                of(this.spinnerService.stop()),
                of(new LessonActions.SetLesson(id))
              )),
            ),
        );
      }),
    );

  @Effect() syncLessonSmartJen$ = this.actions$
    .pipe(
      ofType<LessonActions.SyncLessonSmartJen>(LessonActions.SYNC_LESSON_SMART_JEN),
      mergeMap(({ id , status}) => {
        if (!status ) {
          return concat(
            of(new QueryActions.QueryInProgress(SYNC_LESSON_SMART_JEN)),
            this.smartJenRepository
              .deleteSyncLesson(id)
              .pipe(
                mergeMap(data => concat(
                  of(new LessonActions.SetLesson(id)),
                  of(new QueryActions.QuerySuccess(SYNC_LESSON_SMART_JEN, data)),
                )),
                catchError(error => of(new QueryActions.QueryFailure(SYNC_LESSON_SMART_JEN, error))))
          );
        }

        return concat(
          of(new QueryActions.QueryInProgress(SYNC_LESSON_SMART_JEN)),
          this.smartJenRepository
            .postSyncLesson(id)
            .pipe(
              mergeMap(data => concat(
                of(new LessonActions.SetLesson(id)),
                of(new QueryActions.QuerySuccess(SYNC_LESSON_SMART_JEN, data)),
              )),
              catchError(error => of(new QueryActions.QueryFailure(SYNC_LESSON_SMART_JEN, error))))
        );

      }));
}
