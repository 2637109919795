import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
  showDialog: boolean;
  @Input() disabled = false;
  @Input() message: string;
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  constructor() { }

  ngOnInit() {
  }

  yes() {
    this.confirm.emit();
  }

  onClose() {
    this.isActive = !this.isActive;
    if (this.close) {
      this.close.emit();
    }
  }
}
