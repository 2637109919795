import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogonLocationService {
  private logonLocationDialogSubject = new BehaviorSubject<boolean>(false);

  logonLocationDialogState$ = this.logonLocationDialogSubject.asObservable();

  toggleLogonLocationDialog(isOpen: boolean) {
    this.logonLocationDialogSubject.next(isOpen);
  }
}
