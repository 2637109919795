import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {get, isEmpty, isArray} from 'lodash';
import {selectors, State} from '../../../../store';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Location, StaffmemberLocation} from '../../../../+settings/model/settings.model';
import {Subject} from 'rxjs';
import {LogonLocationData} from '../../../../core/auth/model';
import {AuthRepository} from '../../../../core/auth/shared/auth.repository';
import {AuthService} from '../../../../core/auth/shared/auth.service';
import * as QueryActions from '../../../../store/query/query.actions';
import {POST_REGISTER_LOGON_LOCATION_QUERY} from '../../../../core/auth/store/auth';
import {AutocompleteLocationComponent} from '../../autocomplete/autocomplete-location/autocomplete-location.component';

@Component({
  selector: 'e-register-logon-location-dialog',
  templateUrl: './register-logon-location-dialog.component.html',
  styleUrls: ['./register-logon-location-dialog.component.scss']
})
export class RegisterLogonLocationDialogComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Output() save: EventEmitter<boolean> = new EventEmitter();
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(AutocompleteLocationComponent) autocompleteComponent: AutocompleteLocationComponent;
  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;

    if (val) {
      if (this.autocompleteComponent) {
        this.autocompleteComponent.onFocus();
      }

      const logonLocation = this.authService.getLogonLocation();

      if (logonLocation) {
        if (this.settingLocations && this.settingLocations.length > 0) {
            this.selectedIndex = this.settingLocations.findIndex(function (loc) {
              return loc && loc.location && loc.location.id === logonLocation.id;
            });
        } else if (this.form.get('location')) {
          this.form.get('location').setValue(logonLocation);
          this.form.get('location').updateValueAndValidity();
        }
      } else {
        this.selectedIndex = null;

        if (this.form.get('location')) {
          this.form.get('location').setValue(null);
          this.form.get('location').updateValueAndValidity();
        }
      }
    }

    this.isActiveChange.emit(this.showDialog);
  }
  isEmpty = isEmpty;
  isArray = isArray;
  showDialog: boolean;
  form: FormGroup;
  submit$ = new Subject<LogonLocationData>();
  private unsubscribe$ = new Subject();
  settingLocations: StaffmemberLocation[] = [];
  selectedIndex = null;
  isSettingLocationsViewDefault = true;
  pending = false;
  logonLocationExists = false;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private authRepository: AuthRepository,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const registeredLogonLocation = this.authService.getLogonLocation();
    this.form = this.buildForm(registeredLogonLocation);
    this.loadLocations();
    this.getSubmitData()
      .subscribe((submitData) => {
        const parsedData = {
          location: get(submitData, 'location.id')
        } as LogonLocationData;

        this.submitLogonLocation(parsedData);
      });
    this.authService.getLogonLocationState()
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((logonLocationState) => {
        this.logonLocationExists = !!logonLocationState;
      });
  }

  private submitLogonLocation(logonLocationData: LogonLocationData) {
    this.pending = true;
    this.authService.setLogonLocationToReapplied(logonLocationData.location);
    this.authRepository.changeLogonLocation(logonLocationData).subscribe(response => {
      this.authService.setLogonLocation(get(response, 'location', null));
      this.authService.clearLogonLocationToReapplied();
      this.store.dispatch(new QueryActions.QuerySuccess(POST_REGISTER_LOGON_LOCATION_QUERY, response));
      this.save.emit(true);
      this.pending = false;
    }, (err) => {
      this.store.dispatch(new QueryActions.QueryFailure(POST_REGISTER_LOGON_LOCATION_QUERY, err));
      this.save.emit(false);
      this.pending = false;
    });
  }

  private buildForm(location: Location) {
    return this.formBuilder
      .group({
        location: [
          location,
        ],
      });
  }

  getSubmitData() {
    return this.submit$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500),
      map(() => this.form.value),
    );
  }

  loadLocations() {
    return this.store.select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(({ linkedStaffMember }) => !!linkedStaffMember),
        map(({ linkedStaffMember }) => linkedStaffMember),
        distinctUntilChanged(),
      )
      .subscribe((linkedStaffMember) => {
        const { locations } = linkedStaffMember;
        this.settingLocations = locations;

        if (
          this.showDialog &&
          !this.authService.getLogonLocation() &&
          this.settingLocations &&
          this.settingLocations.length === 1
        ) {
          this.pending = true;

          setTimeout(() => {
            this.selectedIndex = 0;
            const locationId = get(this.settingLocations, '[0].location.id');

            if (!locationId) {
              this.pending = false;

              return;
            }

            this.submitLogonLocation({location: locationId} as LogonLocationData);
          }, 3000);
        }
      });
  }

  selectLocation(index) {
    const foundLocation = this.settingLocations[index];

    if (!foundLocation) {
      return;
    }

    this.selectedIndex = index;
    this.form.controls['location'].setValue(foundLocation.location);
  }

  toggleSettingLocationsView() {
    this.isSettingLocationsViewDefault = !this.isSettingLocationsViewDefault;
  }

  checkFormValid() {
    const locationValue = this.form.controls['location'].value;

    return !isEmpty(locationValue);
  }

  onClose() {
    const locationControl = this.form.get('location');
    locationControl.clearValidators();
    locationControl.updateValueAndValidity();

    this.isActive = false;
    this.isActiveChange.emit(false);
    this.close.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
