import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {selectors, State} from '../../../store';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {findIndex} from 'lodash';
import {IStepOption} from 'ngx-tour-md-menu';
import {navigationRoutes} from './navigation-routes';
import {PermissionsService} from '../../permissions/shared/permissions.service';
import {layoutActions} from '../store';
import {steps} from '../../../../constants/step-helper';
import {NavigationRoute} from '../../../shared/model';

@Injectable()
export class LayoutService {
  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<State>,
    private permissionsService: PermissionsService,
  ) { }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSidenavStatus() {
    return this.store
      .select(selectors.selectLayout)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ isSidenavActive }) => isSidenavActive)
      );
  }

  getMe() {
    return this.store
      .select(selectors.selectAuth)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ linkedStaffMember }) => linkedStaffMember)
      );
  }

  getNavigationRoutes() {
    return navigationRoutes.filter((navigationRoute: NavigationRoute) => this.permissionsService.hasAccessNavigationRoute(navigationRoute));
  }

  getHelperStatus() {
    return this.store
      .select(selectors.selectLayout)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ isHelperActive }) => isHelperActive)
      );
  }

  getCurrentStepHelper() {
    return this.store
      .select(selectors.selectLayout)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ currentStep }) => currentStep)
      );
  }

  getNextStepHelper() {
    return this.store
      .select(selectors.selectLayout)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ currentStep }) => {
          const iCurrentStep = findIndex(steps, (step: IStepOption) => step.anchorId === currentStep.anchorId);
          return steps[iCurrentStep + 1] ? steps[iCurrentStep + 1] : null;
        })
      );
  }

  getIsShowGooglePopup() {
    return this.store
      .select(selectors.selectLayout)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ isShowGooglePopup }) => isShowGooglePopup)
      );
  }

  isFixedSidenavHeight() {
    return this.store
      .select(selectors.selectLayout)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(layout => !!(layout && layout.fixedSidenavHeight)),
        startWith(false)
      );
  }

  toggleSidenav() {
    return this.store.dispatch(new layoutActions.SidenavToggle());
  }

}
