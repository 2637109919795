import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import { Lesson, Lessons, LessonQuery, MakeupData, Absences, Booking, SettingLesson, DiscountSeries} from '../../model';
import { Classroom, LearningCenterSpecialization } from '../../../core/learning-center/model/learning-center.model';
import {UIEnum} from '../../../../constants/UI.enum';

export const CREATE_LESSON = '[Lesson] CREATE_LESSON';
export const DELETE_LESSON = '[Lesson] DELETE_LESSON';
export const DELETE_LESSONS = '[Lesson] DELETE_LESSONS';
export const DELETE_LESSON_SUCCESS = '[Lesson] DELETE_LESSON_SUCCESS';
export const COMPLETE_LESSON = '[Lesson] COMPLETE_LESSON';
export const COMPLETE_LESSON_SUCCESS = '[Lesson] COMPLETE_LESSON_SUCCESS';
export const EDIT_LESSON = '[Lesson] EDIT_LESSON';
export const EDIT_LESSONS = '[Lesson] EDIT_LESSONS';
export const EDIT_LESSON_TEACHER = '[Lesson] EDIT_LESSON_TEACHER';
export const EDIT_ATTENDANCE_LESSON = '[Lesson] EDIT_ATTENDANCE_LESSON';
export const SET_LESSON = '[Lesson] SET_LESSON';
export const SET_LESSON_SUCCESS = '[Lesson] SET_LESSON_SUCCESS';
export const CLEAR_LESSON = '[Lesson] CLEAR_LESSON';
export const GET_LESSONS = '[Lesson] GET_LESSONS';
export const GET_LESSONS_SUCCESS = '[Lesson] GET_LESSONS_SUCCESS';
export const CLEAR_LESSONS = '[Lesson] CLEAR_LESSONS';
export const ASSIGN_TO_LESSON = '[Lesson] ASSIGN_TO_LESSON';
export const REMOVE_LESSON_ON_LIST = '[Lesson] REMOVE_LESSON_ON_LIST';
export const ADD_LESSON_ON_LIST = '[Lesson] ADD_LESSON_ON_LIST';
export const UPDATE_LESSON_ON_LIST = '[Lesson] UPDATE_LESSON_ON_LIST';
export const CREATE_MAKEUP = '[Lesson] CREATE_MAKEUP';
export const GET_ABSENCE = '[Lesson] GET_ABSENCE';
export const GET_ABSENCE_SUCCESS = '[Lesson] GET_ABSENCE_SUCCESS';
export const CLEAR_ABSENCE = '[Lesson] CLEAR_ABSENCE';
export const CLEAR_ABSENCES = '[Lesson] CLEAR_ABSENCES';
export const UPDATE_ABSENCE = '[Lesson] UPDATE_ABSENCE';
export const UPDATE_ABSENCE_SUCCESS = '[Lesson] UPDATE_ABSENCE_SUCCESS';
export const UNDO_STUDENT_ASSIGNED = '[Lesson] UNDO_STUDENT_ASSIGNED';
export const UNDO_STUDENT_ASSIGNED_SUCCESS = '[Lesson] UNDO_STUDENT_ASSIGNED_SUCCESS';
export const GET_RECURRING_LESSONS = '[Lesson] GET_LESSONS';
export const GET_RECURRING_LESSONS_SUCCESS = '[Lesson] GET_LESSONS_SUCCESS';
export const CLEAR_RECURRING_LESSONS = '[Lesson] CLEAR_LESSONS';
export const EDIT_ATTACHED_STUDENTS_LESSON = '[Lesson] EDIT_ATTACHED_STUDENTS_LESSON';
export const UPDATE_BOOKING_LESSONS = '[Lesson] UPDATE_BOOKING_LESSONS';
export const GET_BOOKINGS = '[Lesson] GET_BOOKINGS';
export const GET_BOOKINGS_SUCCESS = '[Lesson] GET_BOOKINGS_SUCCESS';
export const GET_BOOKING = '[Lesson] GET_BOOKING';
export const SET_BOOKING = '[Lesson] SET_BOOKING';
export const CLEAR_BOOKING = '[Lesson] CLEAR_BOOKING';
export const CLEAR_BOOKINGS = '[Lesson] CLEAR_BOOKINGS';
export const CLEAR_PUT_LESSON_QUERY = '[Lesson] CLEAR_PUT_LESSON_QUERY';
export const POST_CLASSROOM = '[Lesson] POST_CLASSROOM';
export const POST_LOCATION = '[Lesson] POST_LOCATION';
export const POST_CATEGORY = '[Lesson] POST_CATEGORY';
export const CLEAR_ALL = '[Lesson] CLEAR_ALL';
export const SET_ORIGINAL_LESSON = '[Lesson] SET_ORIGINAL_LESSON';
export const CLEAR_ORIGINAL_LESSON = '[Lesson] CLEAR_ORIGINAL_LESSON';

export const CREATE_TEACHER_NOTE = '[Lesson] CREATE_TEACHER_NOTE';
export const UPDATE_TEACHER_NOTE = '[Lesson] UPDATE_TEACHER_NOTE';
export const DELETE_TEACHER_NOTE = '[Lesson] DELETE_TEACHER_NOTE';
export const DELETE_TEACHER_NOTE_SUCCESS = '[Lesson] DELETE_TEACHER_NOTE_SUCCESS';
export const GET_TEACHER_NOTE = '[Lesson] GET_TEACHER_NOTE';
export const GET_TEACHER_NOTE_SUCESS = '[Lesson] GET_TEACHER_NOTE_SUCESS';
export const CLEAR_TEACHER_NOTE = '[Lesson] CLEAR_TEACHER_NOTE';
export const EDIT_ONLINE_LESSON_SERIAL = '[Lesson] EDIT_ONLINE_LESSON_SERIAL';
export const EDIT_ONLINE_LESSON = '[Lesson] EDIT_ONLINE_LESSON';
export const EDIT_SETTING_LESSON_SERIAL = '[Lesson] EDIT_SETTING_LESSON_SERIAL';
export const EDIT_DISCOUNT_SERIES = '[Lesson] EDIT_DISCOUNT_SERIES';

export const GET_LAST_LESSONS = '[Lesson] GET_LAST_LESSONS';
export const GET_LAST_LESSONS_SUCCESS = '[Lesson] GET_LAST_LESSONS_SUCCESS';
export const CLEAR_LAST_LESSONS = '[Lesson] CLEAR_LAST_LESSONS';
export const TRANSFER_STUDENTS = '[Lesson] TRANSFER_STUDENTS';
export const SYNC_LESSON_SMART_JEN = '[Lesson] SYNC_LESSON_SMART_JEN';

export class CreateLesson implements Action {
  readonly type = CREATE_LESSON;

  constructor(
    public lesson: LessonQuery,
    public redirect?: string,
    public params?: Params
  ) { }
}

export class DeleteLesson implements Action {
  readonly type = DELETE_LESSON;

  constructor(
    public id: string,
    public redirectUrl?: string,
  ) { }
}

export class DeleteLessons implements Action {
  readonly type = DELETE_LESSONS;

  constructor(
    public queryParams: Params,
  ) { }
}

export class DeleteLessonSuccess implements Action {
  readonly type = DELETE_LESSON_SUCCESS;

  constructor(public id: string) { }
}

export class CompleteLesson implements Action {
  readonly type = COMPLETE_LESSON;

  constructor(
    public id: string,
    public redirectUrl?: string,
    public queryParams?: Params,
  ) { }
}

export class CompleteLessonSuccess implements Action {
  readonly type = COMPLETE_LESSON_SUCCESS;

  constructor(public id: string) { }
}

export class EditLesson implements Action {
  readonly type = EDIT_LESSON;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class EditLessons implements Action {
  readonly type = EDIT_LESSONS;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class EditLessonTeacher implements Action {
  readonly type = EDIT_LESSON_TEACHER;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class SetLesson implements Action {
  readonly type = SET_LESSON;

  constructor(public id: string) { }
}

export class SetLessonSuccess implements Action {
  readonly type = SET_LESSON_SUCCESS;

  constructor(public response: Lesson) { }
}

export class ClearLesson implements Action {
  readonly type = CLEAR_LESSON;

  constructor() { }
}

export class GetLessons implements Action {
  readonly type = GET_LESSONS;

  constructor(public queryParams: Params, public saveLastItemPerPage = UIEnum.SAVE_LAST_ITEM_PER_PAGE_OFF) { }
}

export class GetLessonsSuccess implements Action {
  readonly type = GET_LESSONS_SUCCESS;

  constructor(public response: Lessons) { }
}

export class ClearLessons implements Action {
  readonly type = CLEAR_LESSONS;

  constructor() { }
}

export class AssignToLesson implements Action {
  readonly type = ASSIGN_TO_LESSON;

  constructor(
    public makeup: MakeupData,
    public redirect?: string
  ) { }
}

export class CreateMakeup implements Action {
  readonly type = CREATE_MAKEUP;

  constructor(
    public lesson: LessonQuery,
    public attachedStudent: string,
    public redirect?: string
  ) { }
}

export class GetAbsence implements Action {
  readonly type = GET_ABSENCE;

  constructor(public queryParams: Params) { }
}

export class GetAbsenceSuccess implements Action {
  readonly type = GET_ABSENCE_SUCCESS;

  constructor(public response: Absences) { }
}

export class ClearAbsence implements Action {
  readonly type = CLEAR_ABSENCE;

  constructor() { }
}

export class ClearAbsences implements Action {
  readonly type = CLEAR_ABSENCES;

  constructor() { }
}

export class GetRecurringLessons implements Action {
  readonly type = GET_RECURRING_LESSONS;

  constructor(public queryParams: Params) { }
}

export class GetRecurringLessonsSuccess implements Action {
  readonly type = GET_RECURRING_LESSONS_SUCCESS;

  constructor(public response: Lessons) { }
}

export class ClearRecurringLessons implements Action {
  readonly type = CLEAR_RECURRING_LESSONS;

  constructor() { }
}

export class UpdateAbsence implements Action {
  readonly type = UPDATE_ABSENCE;

  constructor(public payload: { id: string, absenceAction: string }) { }
}

export class UpdateAbsenceSuccess implements Action {
  readonly type = UPDATE_ABSENCE_SUCCESS;

  constructor(public response: any) { }
}

export class UndoStudentAssigned implements Action {
  readonly type = UNDO_STUDENT_ASSIGNED;

  constructor(public studentId: string) { }
}

export class UndoStudentAssignedSuccess implements Action {
  readonly type = UNDO_STUDENT_ASSIGNED_SUCCESS;

  constructor(public response: any) { }
}

export class EditAttachedStudentsLesson implements Action {
  readonly type = EDIT_ATTACHED_STUDENTS_LESSON;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class UpdateBookingLessons implements Action {
  readonly type = UPDATE_BOOKING_LESSONS;

  constructor(public data: any) { }
}

export class GetBookings implements Action {
  readonly type = GET_BOOKINGS;

  constructor(public queryParams: Params) { }
}

export class GetBookingsSuccess implements Action {
  readonly type = GET_BOOKINGS_SUCCESS;

  constructor(public response: any) { }
}

export class GetBooking implements Action {
  readonly type = GET_BOOKING;

  constructor(public id: string) { }
}

export class SetBooking implements Action {
  readonly type = SET_BOOKING;

  constructor(public response: Booking) { }
}

export class ClearBooking implements Action {
  readonly type = CLEAR_BOOKING;

  constructor() { }
}

export class ClearBookings implements Action {
  readonly type = CLEAR_BOOKINGS;

  constructor() { }
}

export class ClearPutLessonQuery implements Action {
  readonly type = CLEAR_PUT_LESSON_QUERY;

  constructor() { }
}

export class CreateClassroom implements Action {
  readonly type = POST_CLASSROOM;

  constructor(
    public data: Classroom,
  ) { }
}

export class CreateLocation implements Action {
  readonly type = POST_LOCATION;

  constructor(
    public data: Classroom,
  ) { }
}

export class ClearAll implements Action {
  readonly type = CLEAR_ALL;

  constructor() { }
}

export class CreateTeacherNote implements Action {
  readonly type = CREATE_TEACHER_NOTE;

  constructor(
    public teacherNote: any,
    public isSend: boolean,
  ) { }
}

export class UpdateTeacherNote implements Action {
  readonly type = UPDATE_TEACHER_NOTE;

  constructor(
    public teacherNote: any,
    public isSend: boolean,
  ) { }
}

export class DeleteTeacherNote implements Action {
  readonly type = DELETE_TEACHER_NOTE;

  constructor(
    public id: string
  ) { }
}

export class DeleteTeacherNoteSuccess implements Action {
  readonly type = DELETE_TEACHER_NOTE_SUCCESS;

  constructor(public id: string) { }
}

export class GetTeacherNote implements Action {
  readonly type = GET_TEACHER_NOTE;

  constructor(
    public lessonId: string
  ) { }
}

export class GetTeacherNoteSuccess implements Action {
  readonly type = GET_TEACHER_NOTE_SUCESS;

  constructor(
    public response: any
  ) { }
}

export class ClearTeacherNote implements Action {
  readonly type = CLEAR_TEACHER_NOTE;

  constructor() { }
}
export class SetOriginalLesson implements Action {
  readonly type = SET_ORIGINAL_LESSON;

  constructor(
    public lesson: Lesson,
  ) { }
}

export class ClearOriginalLesson implements Action {
  readonly type = CLEAR_ORIGINAL_LESSON;

  constructor() { }
}

export class EditOnlineLessonSerial implements Action {
  readonly type = EDIT_ONLINE_LESSON_SERIAL;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class EditOnlineLesson implements Action {
  readonly type = EDIT_ONLINE_LESSON;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class EditSettingLessonSerial implements Action {
  readonly type = EDIT_SETTING_LESSON_SERIAL;

  constructor(
    public id: string,
    public lesson: SettingLesson,
    public redirect?: string
  ) { }
}
export class EditDiscountSeries implements Action {
  readonly type = EDIT_DISCOUNT_SERIES;

  constructor(
    public id: string,
    public series: DiscountSeries[],
    public redirect?: string
  ) { }
}

export class GetLastLessons implements Action {
  readonly type = GET_LAST_LESSONS;

  constructor(public queryParams: Params) { }
}

export class GetLastLessonsSuccess implements Action {
  readonly type = GET_LAST_LESSONS_SUCCESS;

  constructor(public response: Lessons) { }
}

export class ClearLastLessons implements Action {
  readonly type = CLEAR_LAST_LESSONS;

  constructor() { }
}

export class CreateCategory implements Action {
  readonly type = POST_CATEGORY;

  constructor(
    public data: LearningCenterSpecialization,
  ) { }
}


export class EditAttendanceLesson implements Action {
  readonly type = EDIT_ATTENDANCE_LESSON;

  constructor(
    public id: string,
    public lesson: LessonQuery,
    public redirect?: string
  ) { }
}

export class TransferStudents implements Action {
  readonly type = TRANSFER_STUDENTS;

  constructor(
    public id: string,
    public data: any,
  ) { }
}

export class SyncLessonSmartJen implements Action {
  readonly type = SYNC_LESSON_SMART_JEN;

  constructor(
    public id: string,
    public status: boolean
  ) { }
}


export type All =
  | CreateLesson
  | DeleteLesson
  | DeleteLessons
  | DeleteLessonSuccess
  | CompleteLesson
  | CompleteLessonSuccess
  | EditLesson
  | EditLessons
  | EditLessonTeacher
  | EditAttendanceLesson
  | SetLesson
  | SetLessonSuccess
  | GetLessons
  | GetLessonsSuccess
  | ClearLessons
  | ClearLesson
  | CreateMakeup
  | GetAbsence
  | GetAbsenceSuccess
  | ClearAbsence
  | UpdateAbsence
  | UpdateAbsenceSuccess
  | UndoStudentAssigned
  | UndoStudentAssignedSuccess
  | GetRecurringLessons
  | GetRecurringLessonsSuccess
  | ClearRecurringLessons
  | UpdateBookingLessons
  | GetBookings
  | GetBookingsSuccess
  | ClearBooking
  | GetBooking
  | SetBooking
  | ClearPutLessonQuery
  | CreateClassroom
  | CreateLocation
  | CreateCategory
  | ClearAll
  | ClearAbsences
  | ClearBookings
  | CreateTeacherNote
  | UpdateTeacherNote
  | DeleteTeacherNote
  | DeleteTeacherNoteSuccess
  | GetTeacherNote
  | GetTeacherNoteSuccess
  | ClearTeacherNote
  | SetOriginalLesson
  | ClearOriginalLesson
  | EditOnlineLessonSerial
  | EditOnlineLesson
  | EditSettingLessonSerial
  | EditDiscountSeries
  | GetLastLessons
  | GetLastLessonsSuccess
  | ClearLastLessons
  | TransferStudents
  | SyncLessonSmartJen;
