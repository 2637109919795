import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup, FormGroupDirective} from '@angular/forms';
import { Observable ,  of ,  Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, switchMap, startWith, exhaustMap, tap, scan, map } from 'rxjs/operators';
import { SettingsRepository } from '../../../../+settings/shared/settings.repository';
import { Location } from '../../../../+settings/model/settings.model';
import { onChangeClear } from '../../../../../utils';
import { OptionLoadded } from '../../../model';
import { Store } from '@ngrx/store';
import { LessonState, lessonActions } from '../../../../+lesson/store';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { LessonService } from '../../../../+lesson/shared/lesson.service';

@Component({
  selector: 'e-autocomplete-location',
  templateUrl: './autocomplete-location.component.html',
  styleUrls: ['./autocomplete-location.component.scss']
})
export class AutocompleteLocationComponent implements OnInit, OnDestroy {
  @Input() formDir: FormGroupDirective;
  @Input() form: FormGroup;
  @Input() hideAddButton: boolean;
  @Input() fieldLabel: string;
  @Input() fieldName: string;
  @Input() data: Location[];
  @Input() required = false;
  @Input() isLoading = false;
  @Input() showButton = true;
  @Input() matIcon = '';
  @Input() showSearchClearIcon = false;
  @Input() onSelectionChange: (item: any, field?: AbstractControl) => void;
  @Input() isForcedEmptySearch = false;
  filteredData: Observable<Location[] | OptionLoadded[]>;
  subject = new Subject();
  nextPage$ = new Subject();
  isActiveAddLocationDialog: boolean;
  selectedLocation: Location;

  constructor(
    private settingsRepository: SettingsRepository,
    private store: Store<LessonState>,
    private lessonService: LessonService,
  ) { }

  ngOnInit() {
    this.subject.pipe(
      debounceTime(700),
      distinctUntilChanged((prev, curr) => {
        if (this.isForcedEmptySearch && curr === '') {
          return false;
        }

        return prev === curr;
      }),
    ).pipe(
      switchMap(keyword => {
        // Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.fetchData(keyword, currentPage)),
          tap(() => currentPage++),
          // Note: This is a custom operator because we also need the last emitted value.
          // Note: Stop if there are no more pages, or no results at all for the current search text.
         takeWhileInclusive(p => p.length > 0),
          scan((allProducts, newProducts) => allProducts.concat(newProducts), []),
        );
      }),
      startWith([{ loading: true }]),
    ).subscribe(data => this.filteredData = of(data));

    // get location after create and push into filteredData
    this.lessonService.getCreateLocationSuccess()
      .subscribe(res => {
        if (res.id) {
          this.filteredData.subscribe(data => {
            if ((data as Location[]).findIndex(x => x.id === res.id) === -1) {
              (data as Location[]).push(res as Location);
              this.filteredData = of(data);
              this.form.controls.location.setValue(res);
              if (this.form.controls.classroom) {
                this.form.controls.classroom.setValue(null);
              }
            }
          });
        }
      });
  }

  getField() {
    return this.form.get(this.fieldName);
  }

  fetchData(value: any, page: number = 1) {
    if (!this.data) {
      return this.settingsRepository
        .getLocations(!!value ? { search: value, page: page.toString() } : { page: page.toString() })
        .pipe(
          map(({ data }) => data)
        );
    } else {
      return of(this.data);
    }
  }

  onFocus() {
    this.subject.next('');
  }

  onSearch(keyword: string) {
    this.subject.next(keyword);
  }

  displayOptionName(option: Location) {
    return !!option ? option.name : null;
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent, elem: HTMLInputElement) {
    const value = event.option.value;
    onChangeClear(this.getField(), value);
    if (this.form.controls.classroom) {
      this.form.controls.classroom.setValue(null);
    }
    // sent data outside
    if (this.onSelectionChange && value) {
      this.onSelectionChange(value, this.getField());
    }
    //
    elem.blur();
  }

  onChange() {
    this.filteredData.subscribe(data => {
      if (data.length === 0) {
        this.filteredData = of([{ loading: true }]);
      }
    });
    const field = this.form.get(this.fieldName);
    onChangeClear(field, null);
    if (this.form.controls.classroom) {
      this.form.controls.classroom.setValue(null);
    }
  }

  onScroll() {
    this.nextPage$.next();
  }

  ngOnDestroy() {
    this.lessonService.unsubscribe();
  }

  onaddLocation() {
    this.toggleAddLocationDialog();
  }

  toggleAddLocationDialog() {
    this.isActiveAddLocationDialog = !this.isActiveAddLocationDialog;
  }

  submitAddLocation(event: any) {
    this.handleLocationBeforeSave(event);
    this.store.dispatch(new lessonActions.CreateLocation(event));
    this.toggleAddLocationDialog();
  }

  handleLocationBeforeSave(data: Location): Location {
    data.name = data.name ? data.name.trim() : '';
    data.address = data.address ? data.address.trim() : '';
    data.note = data.note ? data.note.trim() : '';
    return data;
  }

  showClearIcon(field: string) {
    return this.form.controls[field].value ? this.form.controls[field].value.trim() : false;
  }

  clearFilter(field: string) {
    this.form.controls[field].setValue(null);
  }

}
