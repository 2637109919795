import {FormArray, FormGroup, Validators} from '@angular/forms';
import { get, isNumber } from 'lodash';
import * as moment from 'moment';
import { getFieldValue, getPrice, regex } from '../../../../utils';
import { noWhitespaceValidator } from '../../../../utils/form-validator';
import { Invoice, InvoiceItem, InvoiceMiscellaneousItem, InvoiceMonthlyItem, PaymentDiscount } from '../../model';

export const getInvoicePersonFields = (data: Invoice, field: string, disabled = false) => {
  return {
    name: [
      {
        value: getFieldValue(data, `${field}.name`),
        disabled,
      },
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
  };
};

export const getInvoiceFields = (data: Invoice, disabled = false) => {
  let discount = +getFieldValue(data, 'discount');
  const type = getFieldValue(data, 'discountType') || PaymentDiscount.Amount;

  if (discount) {
    discount = type === PaymentDiscount.Amount ? +getPrice(discount, 2) : +(discount / 100).toFixed(2);
  }

  return {
    parent: [
      {
        value: getFieldValue(data, 'parentSnapshot') ? getFieldValue(data, 'parentSnapshot') : getFieldValue(data, 'parent'),
        disabled,
      },
      // [ Validators.required ],
    ],
    student: [
      {
        value: getFieldValue(data, 'students[0]') || getFieldValue(data, 'miscellaneousItems[0].student'),
      disabled,
      },
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    number: [
      {
        value: getFieldValue(data, 'prefix') + getFieldValue(data, 'number'),
        disabled,
      },
    ],
    order: [
      getFieldValue(data, 'order'),
      [ Validators.pattern(regex.numbers) ],
    ],
    dueDate: [
      getFieldValue(data, 'dueDate') || moment().add(1, 'days').toISOString(),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    internalNote: [
      getFieldValue(data, 'internalNote'),
      [
        Validators.maxLength(1500),
      ]
    ],
    externalNote: [
      getFieldValue(data, 'externalNote'),
      [
        Validators.maxLength(1500),
      ]
    ],
    parentInvoiceNumber: [
      {
        value: getFieldValue(data, 'prefix') + getFieldValue(data, 'number'),
        disabled: true,
      },
    ],
    totalNet: [
      {
        value: getFieldValue(data, 'totalNet'),
        disabled: true,
      },
    ],
    tax: [
      getFieldValue(data, 'tax'),
      [
        Validators.pattern(regex.numbers),
        Validators.pattern(regex.max100),
        Validators.maxLength(3),
      ]
    ],
    discount: [
      discount,
      [
        Validators.pattern(regex.realNumbers),
        Validators.min(0)
      ],
    ],
    discountType: [
      type,
    ],
    totalGross: [
      {
        value: getFieldValue(data, 'totalGross'),
        disabled,
      },
    ],
    discountNote: [
      getFieldValue(data, 'discountNote'),
    ],
    serviceFee: [
      +getFieldValue(data, 'serviceFee'),
    ],
    serviceFeePayme: [
      +getFieldValue(data, 'serviceFeePayme'),
    ],
    paymentGatewayType: [
      getFieldValue(data, 'paymentGatewayType'),
    ],
  };
};

export const getSalePrice = (discount: number, discountType: string, price: number) => {
  if (!discountType || !discount) { return price; }
  return discountType === PaymentDiscount.Amount ? price - discount * 100 : (price * (100 - discount)) / 100;
};

export const getInvoiceItemFields = (data: InvoiceMiscellaneousItem, isSetTotalDiscount = false) => {
  const unitPrice = getFieldValue(data, 'unitPrice');
  let discount = +getFieldValue(data, 'discount');
  const type = getFieldValue(data, 'discountType') || PaymentDiscount.Amount;
  const quantity = +getFieldValue(data, 'quantity');
  let salePrice = +unitPrice * quantity;
  if (discount) {
    discount = type === PaymentDiscount.Amount ? +getPrice(discount, 2) : +(discount / 100).toFixed(2);
    salePrice = getSalePrice(discount, type, salePrice);
  }
  return {
    student: [
      getFieldValue(data, 'student'),
    ],
    name: [
      getFieldValue(data, 'name'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    quantity: [
      getFieldValue(data, 'quantity'),
      [
        Validators.required,
        Validators.min(1),
        Validators.pattern(regex.numbers),
        noWhitespaceValidator
      ],
    ],
    unitPrice: [
      unitPrice ? getPrice(+unitPrice, 2) : getPrice(0, 2),
      [
        Validators.required,
        Validators.pattern(regex.realNumbers),
        noWhitespaceValidator
      ],
    ],
    unitPriceValue: [
      {
        value: unitPrice,
        disabled: false
      }
    ],
    salePrice: [
      {
        value: salePrice ? getPrice(+salePrice, 2) : getPrice(0, 2),
        disabled: true
      }
    ],
    totalPrice: [
      {
        value: salePrice,
        disabled: false
      }
    ],
    discount: [
      {
        value: discount,
        disabled: isSetTotalDiscount
      },
      [
        Validators.pattern(regex.realNumbers),
        Validators.min(0)
      ]
    ],
    discountValue: [
      {
        value: discount,
        disabled: false
      }
    ],
    discountType: [
      {
        value: type,
        disabled: isSetTotalDiscount
      },
    ],
    discountTypeValue: [
      {
        value: type,
        disabled: false,
      }
    ],
    coursePackageMiscellaneousItem: [
      getFieldValue(data, 'coursePackageMiscellaneousItem')
    ]
  };
};

export const getInvoiceMonthlyLessonFields = (data: InvoiceItem, totalDiscount: number = 0) => {
  const isMainItem = get(data, 'isMainItem', false);
  let unitPrice = '0.00';
  let salePrice = '0.00';
  let discount = '0';
  let type = PaymentDiscount.Amount;
  if (isMainItem) {
    unitPrice = getPrice(parseFloat(data.unitPrice.toString()), 2);
    discount = type === PaymentDiscount.Amount ? getPrice(data.discount, 0) : String(data.discount || '0');
    type = getFieldValue(data, 'discountType') as PaymentDiscount || PaymentDiscount.Amount;
    salePrice = unitPrice;
    if (data.discount && data.discount > 0) {
      salePrice = (() => {
        if (type === PaymentDiscount.Amount) {
          return getPrice(data.unitPrice - data.discount, 2);
        } else {
          const discountValue = data.discount / 100;
          const discountPercent = discountValue / 100;
          const amountValue = data.unitPrice / 100;
          const discountAmount = (amountValue * (discountPercent));
          const result = amountValue - discountAmount;
          return result.toFixed(2);
        }
      })();
    }
  }

  return {
    lesson: [
      getFieldValue(data, 'attachedStudent.lesson'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    unitPrice: [
      {
        value: unitPrice,
        disabled: !getFieldValue(data, 'isMainItem')
      },
      [
        Validators.required,
        Validators.pattern(regex.realNumbers),
        Validators.min(0),
        noWhitespaceValidator
      ],
    ],
    unitPriceValue: [
      {
        value: unitPrice,
        disabled: false
      }
    ],
    salePrice: [
      {
        value: salePrice,
        disabled: true,
      }
    ],
    totalPrice: [
      {
        value: salePrice,
        disabled: false
      }
    ],
    student: [
      getFieldValue(data, 'attachedStudent.student') || getFieldValue(data, 'student'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    attachedStudentId: [
      getFieldValue(data, 'attachedStudent.id') || getFieldValue(data, 'attachedStudent.lesson.attachedStudentId'),
      [Validators.required],
    ],
    discount: [
      {
        value: discount,
        disabled: !getFieldValue(data, 'isMainItem') || totalDiscount > 0,
      }
    ],
    discountValue: [
      {
        value: discount,
        disabled: false
      }
    ],
    discountType: [
      {
        value: type,
        disabled: !getFieldValue(data, 'isMainItem') || totalDiscount > 0,
      }
    ],
    discountTypeValue: [
      {
        value: type,
        disabled: false,
      }
    ],
    teacherName: [
      {
        value: getFieldValue(data, 'teacherName'),
        disabled: true
      }
    ],
    teacherArchived: [getFieldValue(data, 'teacherArchived')],
    courseCode: [
      {
        value: getFieldValue(data, 'attachedStudent.lesson.courseCode'),
        disabled: true
      }
    ],
    lessonPriceType: [
      {
        value: 'monthly',
        disabled: false
      }
    ],
    isMainItem: [
      {
        value: getFieldValue(data, 'isMainItem'),
        disabled: false
      }
    ],
    groupId: [
      {
        value: getFieldValue(data, 'groupId'),
        disabled: false
      }
    ]
  };
};

export const getInvoiceLessonFields = (data: InvoiceItem, isSetTotalDiscount = false) => {
  const unitPrice = isNumber(getFieldValue(data, 'unitPrice'))
    ? getFieldValue(data, 'unitPrice')
    : getFieldValue(data, 'attachedStudent.lesson.price');
  let discount = +getFieldValue(data, 'discount');
  const type = getFieldValue(data, 'discountType') || PaymentDiscount.Amount;
  let salePrice = +unitPrice;
  if (discount) {
    discount = type === PaymentDiscount.Amount ? +getPrice(discount, 2) : +(discount / 100).toFixed(2);
    salePrice = getSalePrice(discount, type, +unitPrice);
  }
  return {
    lesson: [
      getFieldValue(data, 'attachedStudent.lesson'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    unitPrice: [
      unitPrice ? getPrice(+unitPrice, 2) : getPrice(0, 2),
      [
        Validators.required,
        Validators.pattern(regex.realNumbers),
        Validators.min(0),
        noWhitespaceValidator
      ],
    ],
    unitPriceValue: [
      {
        value: unitPrice,
        disabled: false
      }
    ],
    salePrice: [
      {
        value: salePrice ? getPrice(+salePrice, 2) : getPrice(0, 2),
        disabled: true
      }
    ],
    totalPrice: [
      {
        value: salePrice,
        disabled: false
      }
    ],
    student: [
      getFieldValue(data, 'attachedStudent.student') || getFieldValue(data, 'student'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    attachedStudentId: [
      getFieldValue(data, 'attachedStudent.id'),
      [ Validators.required ],
    ],
    discount: [
      {
        value: discount,
        disabled: isSetTotalDiscount,
      },
      [
        Validators.pattern(regex.realNumbers),
        Validators.min(0)
      ],
    ],
    discountValue: [
      {
        value: discount,
        disabled: false
      }
    ],
    discountType: [
      {
        value: type,
        disabled: isSetTotalDiscount,
      },
    ],
    discountTypeValue: [
      {
        value: type,
        disabled: false,
      }
    ],
    teacherName: [
      {
        value: getFieldValue(data, 'teacherName'),
        disabled: true
      }
    ],
    teacherArchived: [ getFieldValue(data, 'teacherArchived') ],
    courseCode: [
      {
        value: getFieldValue(data, 'attachedStudent.lesson.courseCode'),
        disabled: true
      }
    ],
    lessonPriceType: [
      {
        value: getFieldValue(data, 'lessonPriceType'),
        disabled: false
      }
    ],
    isMainItem: [
      {
        value: getFieldValue(data, 'isMainItem'),
        disabled: false
      }
    ],
    groupId: [
      {
        value: getFieldValue(data, 'groupId'),
        disabled: false
      }
    ]
  };
};

export const getInvoiceMonthlyPriceLessonFields = (data: InvoiceItem, isSetTotalDiscount = false) => {
  const unitPrice = isNumber(getFieldValue(data, 'unitPrice'))
    ? getFieldValue(data, 'unitPrice')
    : getFieldValue(data, 'attachedStudent.lesson.price');
  let discount = +getFieldValue(data, 'discount');
  const type = getFieldValue(data, 'discountType') || PaymentDiscount.Amount;
  let salePrice = +unitPrice;
  if (discount) {
    discount = type === PaymentDiscount.Amount ? +getPrice(discount, 2) : +(discount / 100).toFixed(2);
    salePrice = getSalePrice(discount, type, +unitPrice);
  }

  const isMainItem = !!data.isMainItem;

  return {
    lesson: [
      getFieldValue(data, 'attachedStudent.lesson'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    unitPrice: [
      {
        value: unitPrice ? getPrice(+unitPrice, 2) : getPrice(0, 2),
        disabled: !isMainItem
      },
      [
        Validators.required,
        Validators.pattern(regex.realNumbers),
        Validators.min(0),
        noWhitespaceValidator
      ],
    ],
    unitPriceValue: [
      {
        value: unitPrice,
        disabled: false
      }
    ],
    salePrice: [
      {
        value: salePrice ? getPrice(+salePrice, 2) : getPrice(0, 2),
        disabled: true
      }
    ],
    totalPrice: [
      {
        value: salePrice,
        disabled: false
      }
    ],
    student: [
      getFieldValue(data, 'attachedStudent.student') || getFieldValue(data, 'student'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    attachedStudentId: [
      getFieldValue(data, 'attachedStudent.id'),
      [ Validators.required ],
    ],
    discount: [
      {
        value: discount,
        disabled: !isMainItem || isSetTotalDiscount,
      },
      [
        Validators.pattern(regex.realNumbers),
        Validators.min(0)
      ],
    ],
    discountValue: [
      {
        value: discount,
        disabled: false
      }
    ],
    discountType: [
      {
        value: type,
        disabled: !isMainItem || isSetTotalDiscount,
      },
    ],
    discountTypeValue: [
      {
        value: type,
        disabled: false,
      }
    ],
    teacherName: [
      {
        value: getFieldValue(data, 'teacherName'),
        disabled: true
      }
    ],
    teacherArchived: [ getFieldValue(data, 'teacherArchived') ],
    courseCode: [
      {
        value: getFieldValue(data, 'attachedStudent.lesson.courseCode'),
        disabled: true
      }
    ],
    lessonPriceType: [
      {
        value: getFieldValue(data, 'lessonPriceType'),
        disabled: false
      }
    ],
    isMainItem: [
      {
        value: isMainItem,
        disabled: false
      }
    ],
    groupId: [
      {
        value: getFieldValue(data, 'groupId'),
        disabled: false
      }
    ]
  };
};

export const getInvoiceMonthlyFields = (data: InvoiceMonthlyItem) => {
  const unitPrice = isNumber(getFieldValue(data, 'unitPrice'))
    ? getFieldValue(data, 'unitPrice')
    : getFieldValue(data, 'attachedStudent.lesson.price');
  let discount = +getFieldValue(data, 'discount');
  const type = getFieldValue(data, 'discountType') || PaymentDiscount.Amount;
  let salePrice = +unitPrice;
  if (discount) {
    discount = type === PaymentDiscount.Amount ? +getPrice(discount, 2) : +(discount / 100).toFixed(2);
    salePrice = getSalePrice(discount, type, +unitPrice);
  }
  return {
    lesson: [
      getFieldValue(data, 'attachedStudent.lesson'),
      [

      ],
    ],
    unitPrice: [
      unitPrice ? getPrice(+unitPrice, 2) : getPrice(0, 2),
      [
        Validators.required,
        Validators.pattern(regex.realNumbers),
        Validators.min(0),
        noWhitespaceValidator
      ],
    ],
    salePrice: [
      {
        value: salePrice ? getPrice(+salePrice, 2) : getPrice(0, 2),
        disabled: true
      }
    ],
    student: [
      getFieldValue(data, 'attachedStudent.student') || getFieldValue(data, 'student'),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    attachedStudentId: [
      getFieldValue(data, 'attachedStudent.id'),
      [ ],
    ],
    discount: [
      discount,
      [
        Validators.pattern(regex.realNumbers),
        Validators.min(0)
      ],
    ],
    discountType: [
      type,
    ],
    pickMonth: [
      getFieldValue(data, 'pickMonth') || moment().add(1, 'days').toISOString(),
      [
        Validators.required,
        noWhitespaceValidator
      ],
    ],
    numberOfLesson: [
      {
        value: getFieldValue(data, 'numberOfLesson'),
        disabled: true
      }
    ],
    pickLesson: [
      getFieldValue(data, 'pickLesson')
    ]
  };
};

export const getSingleItemPrice = (item: FormGroup): number => {
  const itemValues = item.getRawValue() || {};
  const total = +getFieldValue(itemValues, 'unitPrice') * (+getFieldValue(itemValues, 'quantity') || 1);
  let discount = +getFieldValue(itemValues, 'discount') || 0;
  const discountType = getFieldValue(itemValues, 'discountType');
  if (discountType === PaymentDiscount.Amount) {
    return total - discount;
  }
  if (discountType === PaymentDiscount.Percentage) {
    discount = discount / 100;
    return total - (total * discount);
  }
  return total;
};

export const getTotalNet = (form: FormGroup) => {
  const monthlyItems = form.get('monthlyLessonItems') as FormArray;
  const perLessonItems = form.get('perLessonItems') as FormArray;
  const formLessons = form.get('items') as FormArray;
  const formItems = form.get('miscellaneousItems') as FormArray;
  let value = 0;

  if (monthlyItems) {
    for (let i = 0; i < monthlyItems.length; i++) {
      if (monthlyItems.at(i).valid || monthlyItems.at(i).disabled) {
        value += getSingleItemPrice(monthlyItems.at(i) as FormGroup);
      }
    }
  }

  if (perLessonItems) {
    for (let i = 0; i < perLessonItems.length; i++) {
      if (perLessonItems.at(i).valid || perLessonItems.at(i).disabled) {
        value += getSingleItemPrice(perLessonItems.at(i) as FormGroup);
      }
    }
  }

  if (formLessons) {
    for (let i = 0; i < formLessons.length; i++) {
      if (formLessons.at(i).valid || formLessons.at(i).disabled) {
        value += getSingleItemPrice(formLessons.at(i) as FormGroup);
      }
    }
  }

  for (let i = 0; i < formItems.length; i++) {
    if (formItems.at(i).valid || formItems.at(i).disabled) {
      value += getSingleItemPrice(formItems.at(i) as FormGroup);
    }
  }

  return value.toFixed(2);
};

export const getTotalGross = (form: FormGroup) => {
  const values = form.getRawValue();
  const taxField = form.get('tax');
  const discountField = form.get('discount');
  const type = form.controls['discountType'].value;

  if (taxField.invalid || discountField.invalid) {
    return (0).toFixed(2);
  }

  const taxValue = +getFieldValue(values, 'tax') / 100 || 0;
  const serviceFee = +getFieldValue(values, 'serviceFee');
  const serviceFeePayme = +getFieldValue(values, 'serviceFeePayme');

  let discountValue = +getFieldValue(values, 'discount') || 0;

  let totalGross = +getTotalNet(form);

  if (!!discountValue) {
    switch (type) {
      case PaymentDiscount.Amount:
        totalGross -= discountValue;
        break;
      case PaymentDiscount.Percentage:
        discountValue = discountValue / 100;
        totalGross -= (totalGross * discountValue);
        break;
    }
  }

  if (!!taxValue) {
    totalGross += (totalGross * taxValue);
  }

  if (serviceFee) {
    totalGross += serviceFee;
  }

  if (serviceFeePayme) {
    totalGross += serviceFeePayme;
  }

  return totalGross.toFixed(2);
};

export const isParentSelected = (form: FormGroup) => {
  return !form.get('parent').invalid;
};

export const isActiveForm = (form: FormGroup) => {
  return !form.get('parent').invalid && !form.get('student').invalid;
};

export const isItemSelected = (form: FormGroup) => {
  const monthlyLessons = form.get('monthlyLessonItems') as FormArray;
  const perLessons = form.get('perLessonItems') as FormArray;
  const formLessons = form.get('items') as FormArray;
  const formItems = form.get('miscellaneousItems') as FormArray;

  const controls = [
    ...(monthlyLessons ? monthlyLessons.controls : []),
    ...(perLessons ? perLessons.controls : []),
    ...(formLessons ? formLessons.controls : [])
  ];

  for (let i = 0; i < controls.length; i++) {
    if (controls[i].valid || controls[i].disabled) {
      return true;
    }
  }

  for (let i = 0; i < formItems.length; i++) {
    if (formItems.at(i).valid || formItems.at(i).disabled) {
      return true;
    }
  }

  return false;
};

export const isMonthlyItemSelected = (form: FormGroup) => {
  const formMonthly = form.get('monthly') as FormArray;
  for (let i = 0; i < formMonthly.length; i++) {
    ///
    const invalid = [];
    const controls = formMonthly.at(i)['controls'];
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    // lessons empty
    if (!((formMonthly.at(i) as FormGroup).get('items') as FormArray).length) {
      return false;
    }

    if (isItemSelected(formMonthly.at(i) as FormGroup)) {
      return true;
    }

    if (formMonthly.at(i).valid || formMonthly.at(i).disabled) {
      return true;
    }
  }

  return false;
};
