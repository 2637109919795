import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { of ,  concat } from 'rxjs';
import { mergeMap, catchError, filter, map } from 'rxjs/operators';
import * as QueryActions from '../../../store/query/query.actions';
import * as CalendarActions from './calendar.actions';
import {GET_CENTER_HOLIDAYS} from './calendar.state';
import { RouterState } from '../../../store/router';
import { CalendarRepository } from '../../shared/calendar.repository';
import { checkRouter } from '../../../../utils';
import { handleParamsGetEvents } from '../../../core/calendar/shared/calendar.utils';

@Injectable()
export class CalendarSettingEffects {

    constructor(
        private actions$: Actions,
        private calendarRepository: CalendarRepository,
    ) { }

    @Effect() getCenterHolidays$ = this.actions$
        .pipe(
            ofType<CalendarActions.GetCenterHolidays>(CalendarActions.GET_CENTER_HOLIDAYS),
            mergeMap(({ queryParams }) => concat(
                of(new QueryActions.QueryInProgress(GET_CENTER_HOLIDAYS)),
                this.calendarRepository
                    .getCenterHolidays(queryParams)
                    .pipe(
                        mergeMap(data => concat(
                            of(new QueryActions.QuerySuccess(GET_CENTER_HOLIDAYS, data.data)),
                            of(new CalendarActions.GetCenterHolidaysSuccess(data.data)),
                        )),
                        catchError(error => concat(
                            of(new QueryActions.QueryFailure(GET_CENTER_HOLIDAYS, error)),
                        )),
                    ),
            )),
        );


    @Effect() fetchCenterHolidays$ = this.actions$
        .pipe(
            ofType<CalendarActions.FetchCenterHolidays>(CalendarActions.FETCH_CENTER_HOLIDAYS),
            mergeMap(({ queryParams }) => concat(
                of(new QueryActions.QueryInProgress(GET_CENTER_HOLIDAYS)),
                this.calendarRepository
                    .getCenterHolidays(queryParams)
                    .pipe(
                        mergeMap(data => concat(
                            of(new QueryActions.QuerySuccess(GET_CENTER_HOLIDAYS, data.data)),
                            of(new CalendarActions.GetCenterHolidaysSuccess(data.data)),
                        )),
                        catchError(error => concat(
                            of(new CalendarActions.GetCenterHolidaysFail(error)),
                        )),
                    ),
            )),
        );

    @Effect() fetchData$ = this.actions$
        .pipe(
            ofType<RouterNavigationAction<RouterState>>(ROUTER_NAVIGATION),
            map(({ payload: { routerState }}) => routerState),
            // need refactor here
            filter(({ url }) => {
                return (checkRouter(url, 'lesson/', true) &&
                    !url.includes('lesson/absences') &&
                    !url.includes('lesson/incompleted') &&
                    !url.includes('lesson/completed') &&
                    !url.includes('lesson/absences') &&
                    !url.includes('lesson/recurring') &&
                    !url.includes('lesson/booking')
                );
            }),
        mergeMap(({ queryParams }) => concat(
            of(new CalendarActions.FetchCenterHolidays(handleParamsGetEvents(queryParams)))
        )),
    );

}
