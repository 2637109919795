import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { SettingsService } from '../../shared/settings.service';
import { Location } from '../../model/settings.model';
import { Route } from '../../../shared/components/navigation';
import { settingRoutes } from '../../../+settings/shared/setting-routes.repository';
import { State } from '../../../store';
import * as SettingActions from '../../store/settings/settings.actions';
import { Classroom } from '../../../core/learning-center/model/learning-center.model';
import { SettingsRepository } from '../../shared/settings.repository';
import * as LayoutActions from '../../../core/layout/store/layout.actions';
import { SnackbarStatus } from '../../../core/layout/components/snackbar/snackbar/snackbar.model';
import { settingsPostParser } from '../../component/settings-form/setting-form.parser';
import { Settings } from '../../model/settings.model';
import {UIEnum} from '../../../../constants/UI.enum';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'e-setting-location',
  templateUrl: './setting-location.component.html',
  styleUrls: ['./setting-location.component.scss']
})
export class SettingLocationComponent implements OnInit, OnDestroy {
  data$: Observable<Location[]>;
  spinnerActive$: Observable<boolean>;
  count$: Observable<number>;
  unsubscribe$ = new Subject();
  routes: Route[];
  selectedLocations: string[];
  params = new URLSearchParams();
  canDelete: boolean;
  isActiveEditDialog: boolean;
  isActiveRemoveDialog: boolean;
  selectedLocation: Location;
  selectedLocationId: string;
  isActiveAddClassroomDialog: boolean;
  idLocationForClassroom: string;
  isActiveViewClassroomDialog: boolean;
  location: Location;
  settings: Settings;
  notAllowToCreateNewLocationInLessonCreation: boolean;
  UIEnum = UIEnum; // enum

  constructor(
    private settingsService: SettingsService,
    private store: Store<State>,
    private settingsRepository: SettingsRepository,
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit() {
    this.spinnerActive$ = this.settingsService.locationsQueryIsPending();
    this.data$ = this.settingsService.getLocations();
    this.count$ = this.settingsService.getLocationsTotalItems();
    this.routes = settingRoutes;
    this.settingsService.getSettings().subscribe((settings) => {
      this.settings = settings;
      this.notAllowToCreateNewLocationInLessonCreation = settings.notAllowToCreateNewLocationInLessonCreation;
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new SettingActions.ClearLocations());
    this.settingsService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onUpdateLocations(value: Location[]) {
    this.selectedLocations = [];
    this.params = new URLSearchParams();
    value.forEach((el) => {
      this.selectedLocations.push(el.id);
      this.params.append('id[]', el.id);
    });
  }

  toggleEditDialog() {
    this.isActiveEditDialog = !this.isActiveEditDialog;
  }

  toggleRemoveDialog() {
    this.isActiveRemoveDialog = !this.isActiveRemoveDialog;
  }

  onEditLocation(event: Location) {
    this.spinnerService.start();
    this.settingsRepository.getLocation(event.id).subscribe((responseLocation) => {
        this.toggleEditDialog();
        this.selectedLocation = responseLocation;
        this.spinnerService.stop();
      }, error => {
        this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, error.error.detail));
        this.spinnerService.stop();
      }
    );
  }

  submitAction(event: Location) {
    this.handleLocationBeforeSave(event);
    if (event.id) {
      this.store.dispatch(new SettingActions.UpdateLocation(event.id, event));
    } else {
      this.store.dispatch(new SettingActions.CreateLocation(event));
    }
    this.toggleEditDialog();
  }

  handleLocationBeforeSave(data: Location): Location {
    data.name = data.name ? data.name.trim() : '';
    data.address = data.address ? data.address.trim() : '';
    data.note = data.note ? data.note.trim() : '';
    return data;
  }

  submitActionRemove() {
    this.store.dispatch(new SettingActions.DeleteLocation(this.selectedLocationId));
    this.toggleRemoveDialog();
  }

  onCreate() {
    this.selectedLocation = null;
    this.toggleEditDialog();
  }

  onRemoveLocation(id: string) {
    this.selectedLocationId = id;
    this.toggleRemoveDialog();
  }

  onaddClassroom(idLocation: string) {
    this.idLocationForClassroom = idLocation;
    this.toggleAddClassroomDialog();
  }

  toggleAddClassroomDialog() {
    this.isActiveAddClassroomDialog = !this.isActiveAddClassroomDialog;
  }

  submitAddClassroom(event: Classroom) {
    this.handleClassroomBeforeSave(event);
    if (event.id) {
      this.store.dispatch(new SettingActions.UpdateClassroom(event.id, event));
    } else {
      this.store.dispatch(new SettingActions.CreateClassroom(event));
    }
    this.isActiveAddClassroomDialog = false;
    this.isActiveViewClassroomDialog = false;
  }

  handleClassroomBeforeSave(data: Classroom): Classroom {
    data.name = data.name ? data.name.trim() : '';
    data.note = data.note ? data.note.trim() : '';
    return data;
  }

  onViewClassroom(location: Location) {
    this.location = location;
    this.toggleViewClassroomDialog();
  }

  toggleViewClassroomDialog() {
    this.isActiveViewClassroomDialog = !this.isActiveViewClassroomDialog;
  }

  removeClassroom(event: string) {
     this.store.dispatch(new SettingActions.DeleteClassroom(event));
     this.isActiveViewClassroomDialog = false;
  }

  submitUpdateConcurrentClassroom(event: Classroom) {
    event.allow = !event.allow;
    this.spinnerService.start();
    this.settingsRepository.putConcurrentClassroom(event.id, event).subscribe(data => {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'settings.location.updateClassroomSuccess'));
      this.spinnerService.stop();
    }, (err) => {
      this.store.dispatch(new LayoutActions.ShowSnackbar(SnackbarStatus.ERROR, err.error.detail));
      this.spinnerService.stop();
      event.allow = !event.allow;
    });
  }

  changeSetting() {
    const data = settingsPostParser({
      ...this.settings,
      notAllowToCreateNewLocationInLessonCreation: this.notAllowToCreateNewLocationInLessonCreation,
      lastInvoiceNumber: get(this.settings, 'lastInvoiceNumber', 0) + 1
    }) as Settings;
    this.store.dispatch(new SettingActions.UpdateSettings(data));
  }

  onPreviewInvoicePdf(locationId: string) {
    this.store.dispatch(new SettingActions.GetPreviewPdf(locationId));
  }
}
