import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'e-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() isOrangeTheme = false;
  showDialog = false;
  @Input() message?: string;
  @Input() disableOutsideClick?: boolean;
  @Input() hideCloseButton?: boolean;
  @Output() isActiveChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  get isActive() {
    return this.showDialog;
  }
  set isActive(val) {
    this.showDialog = val;
    this.isActiveChange.emit(this.showDialog);
  }

  toggleDialog() {
    return this.isActive = !this.isActive;
  }
}
