import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {concat, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {get} from 'lodash';
import {AuthRepository} from '../../shared/auth.repository';
import {AuthService} from '../../shared/auth.service';
import {LoginCredentials, PasswordData, RequestForResetData} from '../../model';
import * as QueryActions from '../../../../store/query/query.actions';
import * as AuthActions from './auth.actions';
import * as RouterActions from '../../../../store/router/router.actions';
import {
  GET_ME_QUERY,
  POST_ACTIVATE_QUERY,
  POST_AUTHORIZE_QUERY,
  POST_REGISTER_LOGON_LOCATION_QUERY,
  POST_REQUEST_FOR_RESET_QUERY,
  SYNC_LOG,
  SYNC_LOG_LOGOUT
} from './auth.state';
import {SnackbarStatus} from '../../../layout/components/snackbar/snackbar/snackbar.model';
import {layoutActions} from '../../../layout/store/index';
import {Location} from '../../../../+settings/model/settings.model';
import {loginErrorMessage} from '../../shared/auth.factory';
import {SpinnerService} from '../../../../services/spinner.service';
import {DialogCenterSelectionEnum} from '../../../../../constants/dialog-center-selection.enum';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authRepository: AuthRepository,
    private authService: AuthService,
    private spinnerService: SpinnerService,
  ) { }

  @Effect() activate$ = this.actions$
    .pipe(
      ofType<AuthActions.AuthActivation>(AuthActions.AUTH_ACTIVATE),
      map(({ passwordData }) => passwordData),
      tap(() => {
        this.authService.clearTokens();
        this.authService.clearLogonLocation();
      }),
      mergeMap(( passwordData: PasswordData ) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(POST_ACTIVATE_QUERY)),
        this.authRepository
          .setPassword(passwordData)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(POST_ACTIVATE_QUERY, data)),
              of(new layoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, 'auth.passwordSet')),
              of(new RouterActions.Navigate({ url: '/login' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(POST_ACTIVATE_QUERY, error))),
            )
          ),
      )),
    );

  @Effect() requestForReset$ = this.actions$
    .pipe(
      ofType<AuthActions.AuthRequestForReset>(AuthActions.AUTH_REQUEST_FOR_RESET),
      map(({ requestForResetData }) => requestForResetData),
      tap(() => {
        this.authService.clearTokens();
        this.authService.clearLogonLocation();
      }),
      mergeMap(( requestForResetData: RequestForResetData ) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(POST_REQUEST_FOR_RESET_QUERY)),
        this.authRepository
          .requestForReset(requestForResetData)
          .pipe(
            mergeMap(data => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QuerySuccess(POST_REQUEST_FOR_RESET_QUERY, data)),
              of(new layoutActions.ShowSnackbar(SnackbarStatus.SUCCESS, get(data, 'message'))),
              of(new RouterActions.Navigate({ url: '/login' })),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(POST_REQUEST_FOR_RESET_QUERY, error))),
            ),
          ),
      )),
    );

  @Effect() login$ = this.actions$
    .pipe(
      ofType<AuthActions.AuthLogin>(AuthActions.AUTH_LOGIN),
      map(({ credentials }) => credentials),
      mergeMap((credentials: LoginCredentials) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(POST_AUTHORIZE_QUERY)),
        this.authRepository
          .authorize(credentials)
          .pipe(
            tap(data => this.authService.authorize(data)),
            mergeMap(data => concat(
              of(this.authService.checkCrossCenterOnAuth(DialogCenterSelectionEnum.POSITION_LOGIN_PAGE)), // also stop the spinner here
              of(new QueryActions.QuerySuccess(POST_AUTHORIZE_QUERY, data)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(POST_AUTHORIZE_QUERY, error)),
              of(new layoutActions.ShowSnackbar(SnackbarStatus.ERROR, loginErrorMessage(error.error.message))),
            )),
          ),
      )),
    );

  @Effect() refreshLogonLocation$ = this.actions$
    .pipe(
      ofType<AuthActions.RefreshLogonLocation>(AuthActions.REFRESH_LOGON_LOCATION),
      mergeMap(() => concat(
        this.authRepository
          .getLogonLocationInfo()
          .pipe(
            tap(data => this.authService.setLogonLocation(get(data, 'location'))),
            mergeMap(data => concat(
            )),
            catchError(error => {
              if (get(error, 'error.detail') === 'ERROR.PROPERTY.MISSING_LOCATION_FOR_TOKEN') {
                let oldLogonLocation = this.authService.getLogonLocation();

                if (oldLogonLocation) {
                  oldLogonLocation = {
                    ...oldLogonLocation,
                    disabled: true
                  } as Location;
                  this.authService.setLogonLocation(oldLogonLocation);
                }
              }

              return of();
            }),
          ),
      )),
    );

  @Effect() registerLogonLocation$ = this.actions$
    .pipe(
      ofType<AuthActions.RegisterLogonLocation>(AuthActions.REGISTER_LOGON_LOCATION),
      mergeMap(({logonLocation, token}) => concat(
        of(new QueryActions.QueryInProgress(POST_REGISTER_LOGON_LOCATION_QUERY)),
        this.authRepository
          .registerLogonLocationWithToken(logonLocation, token)
          .pipe(
            tap(data => {
              this.authService.setLogonLocation(get(data, 'location'));
              this.authService.clearLogonLocationToReapplied();
            }),
            map(() => new QueryActions.QuerySuccess(POST_REGISTER_LOGON_LOCATION_QUERY)),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_REGISTER_LOGON_LOCATION_QUERY, error)),
              of(new layoutActions.ShowSnackbar(SnackbarStatus.ERROR, error))
            )),
          ),
      )),
    );

  @Effect() revokeLogonLocation$ = this.actions$
    .pipe(
      ofType<AuthActions.RevokeLogonLocation>(AuthActions.REVOKE_LOGON_LOCATION),
      mergeMap((response) => concat(
        this.authRepository
          .revokeLogonLocation()
          .pipe(
            tap(() => this.authService.clearLogonLocation()),
            catchError(error => concat(
              of(new layoutActions.ShowSnackbar(SnackbarStatus.ERROR, error)),
            )),
          ),
      )),
    );

  @Effect() logout$ = this.actions$
    .pipe(
      ofType<AuthActions.AuthLogout>(AuthActions.AUTH_LOGOUT),
      tap(() => {
        this.authService.logout();
      }),
      mergeMap(() => concat(
        of(this.spinnerService.stop()),
        of(new QueryActions.QueryClear(GET_ME_QUERY)),
        of(new RouterActions.Navigate({ url: '/login' })),
      )),
    );

  @Effect() getRefreshToken$ = this.actions$
    .pipe(
      ofType<AuthActions.AuthRefreshToken>(AuthActions.AUTH_REFRESH_TOKEN),
      tap(({ response }) => this.authService.authorize(response)),
      map(({ response }) => new QueryActions.QuerySuccess(POST_AUTHORIZE_QUERY, response)),
      catchError(error => {
        this.authService.clearAllSwitchUserData();
        return concat(
          of(new QueryActions.QueryFailure(POST_AUTHORIZE_QUERY, error)),
          of(new RouterActions.Navigate({ url: '/login' })),
        );
      }),
    );

    @Effect() getMe$ = this.actions$
    .pipe(
      ofType<AuthActions.AuthGetMe>(AuthActions.AUTH_GET_ME),
      mergeMap(({navigateHome}) => concat(
        of(new QueryActions.QueryInProgress(GET_ME_QUERY)),
        this.authRepository
          .getMe()
          .pipe(
            tap(linkedStaffMember => this.authService.handleGetMe(linkedStaffMember, navigateHome)),
            mergeMap(linkedStaffMember => concat(
              of(new QueryActions.QuerySuccess(GET_ME_QUERY, linkedStaffMember)),
            )),
            catchError(error => concat(
              of(this.spinnerService.stop()),
              of(new QueryActions.QueryFailure(GET_ME_QUERY, error))),
            ),
          ),
      )),
    );

  // duplicate handle
  // @Effect() verifyToken$ = this.actions$
  //   .pipe(
  //     ofType<AuthActions.VerifyToken>(AuthActions.VERIFY_TOKEN),
  //     mergeMap(({ token }) => concat(
  //       of(new QueryActions.QueryInProgress(GET_ACTIVATE_QUERY)),
  //       this.authRepository
  //         .verifyToken(token)
  //         .pipe(
  //           map(data => new QueryActions.QuerySuccess(GET_ACTIVATE_QUERY, data)),
  //           catchError(error => of(new QueryActions.QueryFailure(GET_ACTIVATE_QUERY, error))),
  //         ),
  //     )),
  //   );

    @Effect() syncLogLogout$ = this.actions$
    .pipe(
      ofType<AuthActions.SyncLogLogout>(AuthActions.SYNC_LOG_LOGOUT),
      mergeMap(() => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(SYNC_LOG_LOGOUT)),
        this.authRepository
          .syncLogLogout()
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(SYNC_LOG_LOGOUT, data)),
              of(this.spinnerService.stop()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(SYNC_LOG_LOGOUT, error)),
              of(this.spinnerService.stop()),
            )),
          ),
      )),
    );

    @Effect() syncLog$ = this.actions$
    .pipe(
      ofType<AuthActions.SyncLog>(AuthActions.SYNC_LOG),
      mergeMap((log) => concat(
        of(this.spinnerService.start()),
        of(new QueryActions.QueryInProgress(SYNC_LOG)),
        this.authRepository
          .syncLog(log.data)
          .pipe(
            mergeMap(data => {
              return concat(
                of(new QueryActions.QuerySuccess(SYNC_LOG, data)),
                of(this.spinnerService.stop()),
              );
            }),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(SYNC_LOG, error)),
              of(this.spinnerService.stop()),
            )),
          ),
      )),
    );

  @Effect() changeLogonLocation$ = this.actions$
    .pipe(
      ofType<AuthActions.ChangeLogonLocation>(AuthActions.CHANGE_LOGON_LOCATION),
      mergeMap(({logonLocation}) => concat(
        of(this.spinnerService.start()),
        this.authRepository
          .changeLogonLocation(logonLocation)
          .pipe(
            tap(data => {
              this.authService.setLogonLocation(get(data, 'location'));
              this.authService.clearLogonLocationToReapplied();
              this.spinnerService.stop();
            }),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_REGISTER_LOGON_LOCATION_QUERY, error)),
              of(new layoutActions.ShowSnackbar(SnackbarStatus.ERROR, error)),
              of(this.spinnerService.stop()),
            )),
          ),
      )),
    );
}
